import {useEffect, useState} from "react";
import FilterSelectors from "./FilterSelectors";

const CircleIndicator = ({text}) => {
	const dimensions = `w-[3.8vh] h-[3.8vh]`;
	return <div className={dimensions + " rounded-[999px] bg-black justify-center items-center flex text-[2.27vh]"}>
		<div className={"text-white text-center font-dm-sans"}>{text}</div>
	</div>
}

const FilterOptions = ({title, filterKey, selections, setSelections}) => {

	const [selectorCounter, setSelectorCounter] = useState(0);

	useEffect(() => {
		setSelectorCounter(Object.keys(selections).filter(item => {
			return selections[item] === true;
		}).length);
	}, [selections]);

	return <div className="h-full w-full">
		<div className="flex">
			<div className="text-[2.65vh] font-dm-sans mr-[3.5%]">{title}</div>
			<CircleIndicator diameter={20} text={selectorCounter}/>
		</div>
		<div className="h-full mt-[1.5vh]">
			<FilterSelectors
				filterKey={filterKey}
				selections={selections}
				setSelections={setSelections}
			/>
		</div>
	</div>
}

export default FilterOptions;
