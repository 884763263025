const FilterMenuIcons = {
	close: ({fill, onClick}) => {
		return <svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
			className="w-[1.2em] h-[1.2em]"
		>
			<path d="M18.875 6.50923L13.3842 12L18.875 17.4908L17.4908 18.875L12
			13.3842L6.50923 18.875L5.125 17.4908L10.6158 12L5.125 6.50923L6.50923
			5.125L12 10.6158L17.4908 5.125L18.875 6.50923Z" fill={fill}/>
		</svg>
	},
	unCheck: ({onClick}) => {
		return <svg
			width="20" height="20"
			viewBox="0 0 20 20" fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="relative w-[1.25em] h-[1.25em] top-[0.02em]"
			onClick={onClick}
		>
			<rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="white" stroke="#9191A1"/>
		</svg>
	},
	check: ({onClick}) => {
		return <svg
			width="20" height="20"
			viewBox="0 0 20 20" fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="relative w-[1.25em] h-[1.25em] top-[0.02em]"
			onClick={onClick}
		>
			<rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="#03A882" stroke="#03A882"/>
			<path d="M5.67383 9.1423H5.67401C5.76606 9.14241 5.85702 9.16284 5.9401 9.20221L5.87586 9.33776L5.9401 9.20221C6.02319 9.24158 6.09639 9.29896 6.15402 9.3703M5.67383 9.1423L8.80404 12.8823L8.90054 12.7631M5.67383 9.1423H4.6615C4.44606 9.1423 4.3101 9.39129 4.45363 9.56877L4.45368 9.56883L8.42044 14.4683L8.42048 14.4684C8.66613 14.7715 9.13505 14.7708 9.38166 14.4688L9.38208 14.4683L16.4545 5.73075C16.4546 5.73055 16.4548 5.73035 16.455 5.73015C16.6011 5.55196 16.4601 5.30459 16.2475 5.30459H15.2352C15.0492 5.30459 14.8711 5.38763 14.7548 5.53275C14.7547 5.5329 14.7546 5.53305 14.7545 5.5332L8.90054 12.7631M5.67383 9.1423L8.99703 12.8823L8.90054 12.7631M6.15402 9.3703L6.03734 9.46456L6.15393 9.37018L6.15402 9.3703ZM6.15402 9.3703L8.90054 12.7631" fill="white" stroke="white" strokeWidth="0.3"/>
		</svg>
	},
}

export default FilterMenuIcons;