import {dateSplitter} from "../../../util/dateFormatters";

const DateDisplay = ({createdAt}) => {

	const {time, date} = dateSplitter(createdAt);

	return <div className="w-[6vw] overflow-x-visible">
		<div className="text-[2.7vh] font-dm-sans">{date}</div>
		<div className="text-[2.27vh] font-dm-sans text-[#9191A1] w-[150%]">{time}</div>
	</div>;
}

export default DateDisplay;