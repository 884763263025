import filterMenuIcons from "../../../assets/filter-menu-icons";

const FilterSelectors = ({selections, setSelections}) => {

	const turnOffFilterBuilder = (title) => () => {
		const newSelections = {...selections};
		Object.assign(newSelections, {[title]: false});
		setSelections(newSelections);
	}

	const turnOnFilterBuilder = (title) => () => {
		const newSelections = {...selections};
		Object.assign(newSelections, {[title]: true});
		setSelections(newSelections);
	}

	const Radio = ({title}) => {

		const turnOffFilter = turnOffFilterBuilder(title);
		const turnOnFilter = turnOnFilterBuilder(title);

		return <div className="flex h-[13.33%] text-[2.98vh]">
			<div className="flex relative my-[1.35vh]">
				<div className="absolute top-0 left-0">
					<filterMenuIcons.unCheck onClick={turnOnFilter}/>
				</div>
				{selections[title] && <div className="absolute top-0 left-0">
					<filterMenuIcons.check onClick={turnOffFilter}/>
				</div>}
			</div>
			<div className="relative flex items-center justify-center my-auto ml-[12.5%]">
				<div className="font-dm-sans">{title}</div>
			</div>
		</div>
	}

	return <div className="border rounded-[1.53vh] h-[90.91%] w-auto px-[4.2%] py-[4.2%]">
		{Object.keys(selections).map((value, index) => <Radio title={value} key={index}/>)}
	</div>
}

export default FilterSelectors;