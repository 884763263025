import React, {useState, useEffect} from 'react';
import {InmateKiwiContextProvider} from "./context/InmateKiwiContext";
import QueryClientContext from "./context/QueryClientContext";
import SideBar from "./components/shared/SideBar";
import TopBar from "./components/shared/TopBar";
import Balance from "./components/shared/Balance";
import RouteController from "./hooks/RouteController";
import LoadSession from "./hooks/LoadSession";
import InfoPanel from "./components/shared/InfoPanel";
import Loading from "./components/shared/Loading";
import {TransitionContextProvider} from "./context/TransitionContext";
import Toast from "./components/shared/Toast";

const WalletApplication = () => {

  const [screen, setScreen] = useState('');
  const [sideMenu, setSideMenu] = useState(false);
  const [infoPanel, setInfoPanel] = useState({
    showPanel: false,
    infoType: 'Trust'
  });

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    setScreen(`min-h-[${windowHeight}px] min-w-[${windowWidth}px] fixed`);

    const handleResize = () => {
      const nonScrollableScreen = `min-h-[${windowHeight}px] min-w-[${windowWidth}px] fixed`;
      const scrollableScreen = `${nonScrollableScreen} bottom-[4%]`;
      setScreen((window.innerHeight < windowHeight) ? scrollableScreen : nonScrollableScreen);
    }

    window.addEventListener('resize', handleResize);

  }, [])

  const toggleMenu = (value) => {
    setSideMenu(value);
  }

  return <div className="bg-[#F7F8F9] h-screen relative w-full">
    <div className="fixed top-0 left-0 z-50">
      <SideBar isOpen={sideMenu}  toggleMenu={toggleMenu}/>
    </div>
    <div className={"h-screen " + screen}>
      <TopBar menuToggle={toggleMenu} />
      <Balance infoPanel={infoPanel} setInfoPanel={setInfoPanel}/>
      <RouteController/>
    </div>
    <Toast/>
    {
        (infoPanel.showPanel) && <div className="fixed top-0 z-40">
          <InfoPanel infoPanel={infoPanel} setInfoPanel={setInfoPanel}/>
        </div>
    }
  </div>

}

const App = () => {

  const { isSessionLoading, session, inmateInfo, kiwiAccount } = LoadSession();

  useEffect(() => {
    const viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute(
        "content",
        viewport.content + ", height=" + window.innerHeight + ", width=device-width, initial-scale=1.0"
    );
  }, [])

  const Application = () => {
    return <InmateKiwiContextProvider loadedData={{session, inmateInfo, kiwiAccount}}>
      <TransitionContextProvider>
        <WalletApplication />
      </TransitionContextProvider>
    </InmateKiwiContextProvider>
  }

  const InitialLoad = () => {
    return <div className="flex h-screen">
      <div className="m-auto">
        <Loading message="Establishing session..." />
      </div>
    </div>
  }

  return <QueryClientContext>
    {(!isSessionLoading) ? <Application /> : <InitialLoad />}
  </QueryClientContext>;
}

export default App;
