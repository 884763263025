import {useInmateContext} from "../../context/InmateKiwiContext";
import {useSubscriptionContext} from "../../context/SubscriptionContext";
import {usePaymentHistoryContext} from "../../context/PaymentHistoryContext";
import {historyDateFormatter} from "../../util/dateFormatters";
import {useQuery} from "react-query";
import {useEffect} from "react";
import Loading from "../shared/Loading";
import {useNotification} from "../../context/NotificationContext";

const ReactivateSubscriptionPrompt = ({OnClick}) => {

	const { signal } = useNotification();
	const { inmate } = useInmateContext();
	const { subscriptions } = useSubscriptionContext();
	const { subscription: { kiwiSubscriptionId,  charge: { TTL } } } = usePaymentHistoryContext();

	const nextPaymentDate = historyDateFormatter((TTL) ? new Date(TTL * 1000) : TTL);

	const setReactivation = () => {
		const index = subscriptions.current.findIndex(item => item.kiwiSubscriptionId === kiwiSubscriptionId);
		Object.assign(subscriptions.current[index].charge, {isCancelled: false});
	}

	const setResults = async () => {
		setReactivation();
		await OnClick();
		signal.success("Subscription successfully re-activated.");
	}

	const reactivateSubscription = async () => {
		const response = await inmate.api.kiwiPay.kiwiSubscription.reactivate({
			kiwiSubscriptionId: kiwiSubscriptionId,
		})

		await setResults();
		return response;
	}

	const {
		status,
		refetch: triggerReactivation,
		isRefetching: isReactivating,
		isRefetchError: errorOnReactivation,
	} = useQuery('ReactivateSubscription', reactivateSubscription, { enabled: false });

	const handleClick = () => {
		triggerReactivation().then(() => console.log("Subscription reactivated"));
	}

	useEffect(() => {
		if(status === "error" || errorOnReactivation) {
			signal.error("Error re-activating subscription, please try again later...")
		}
	}, [status, errorOnReactivation])

	const ReactivatePrompt = () => {
		return <div className="animate-fade-in">
			<div className="text-[4.53vh] font-dm-sans">Re-activate Subscription</div>
			<div className="text-[2.643vh] font-dm-sans mt-[3vh]">
				<div>
					Your next payment will be on {nextPaymentDate} upon re-activation.
				</div>
				<div>
					Do you want to continue to reactivate this subscription?
				</div>
			</div>
			<div className="mt-[4.53vh] float-right">
				<div className="flex my-[4.53vh] w-fit">
					<div className="py-[2.26vh] px-[2.82vw] text-[3.02vh] font-dm-sans" onClick={OnClick}>
						No, leave cancelled
					</div>
					<div className="py-[2.26vh] px-[2.82vw] bg-[#038768] rounded-xl" onClick={handleClick}>
						<div className="text-[#FFFFFF] text-[3.02vh] font-dm-sans">Yes, reactivate</div>
					</div>
				</div>
			</div>
		</div>
	}

	const IsLoading = () => {
		return <div className="h-[40.5vh]">
			<Loading message="Reactivating subscription..."/>
		</div>
	}

	return <div>
		{(isReactivating || status === "loading") ? <IsLoading/> : <ReactivatePrompt/>}
	</div>

}

export default ReactivateSubscriptionPrompt;