import {useEffect, useState} from "react";
import BalanceIcons from "../../assets/balance-icons";
import {useNotification} from "../../context/NotificationContext";

const Toast = () => {

	const red = 'bg-[#EE0000]';
	const green = 'bg-[#038768]';

	const {
		message,
		animation,
		currentId,
		setMessage,
		setCurrentId,
		setAnimation,
		notifications,
		triggerAnimation,
		nextNotificationInQueue,
	} = useNotification();

	const [show, setShow] = useState(false);
	const [background, setBackground] = useState(green);

	const handleClick = () => {
		triggerAnimation();
	}

	useEffect(() => {
		const notification = nextNotificationInQueue();
		setShow(!(notifications.length === 0));
		if(notification?.id !== currentId && notifications.length !== 0) {
			setCurrentId(notification?.id);
			setMessage(notification.message);
			setAnimation('animate-model-drop');
			setBackground((notification.type === "error") ? red : green);
			setTimeout(() => {
				setAnimation('');
			}, 450);
		}
	}, [notifications]);

	const styling = `flex w-[50%] h-[12%] rounded-[2.2vh] px-[2.5vh]
	py-[2vh] text-[2.7vh] ${background} ${animation} absolute top-[3%] z-10 left-[25%]`;

	const ToastBody = () => {
		return <div className={styling}>
			<div className="w-full text-white flex justify-items-center items-center">
				{message}
			</div>
			<div className="w-[10%] flex justify-items-center items-center text-[4vh]" onClick={handleClick}>
				<div className="relative bottom-[0.25em] left-[0.45em]">
					<BalanceIcons.CLOSE fill={"white"}/>
				</div>
			</div>
		</div>
	}

	return <div>
		{(show) && <ToastBody/>}
	</div>

}

export default Toast;