// Deprecated page, the new page is Transfer
// import BalanceTransfer from "./routeController/BalanceTransfer";

import {Route, Routes} from "react-router-dom";

import TransactionHistory from "../routes/TransactionHistory";
import Subscriptions from "../routes/Subscriptions";
import Transfer from "../routes/Transfer";

const routes = {
	transactionHistory: {
		key: 1,
		path: '/transaction-history',
		element: <TransactionHistory/>
	},
	subscriptions: {
		key: 2,
		path: '/subscriptions',
		element: <Subscriptions/>
	},
	transfer: {
		key: 3,
		path: '/',
		element: <Transfer/>
	},
	_transfer: {
		key: 4,
		path: '/transfers',
		element: <Transfer/>
	}
}

const RouteController = () => {
	return <Routes>
		{Object.values(routes).map(route => <Route key={route.key} path={route.path} element={route.element} />)}
	</Routes>
}

export default RouteController;