import balanceIcons from "../../assets/balance-icons";
import {useInmateContext} from "../../context/InmateKiwiContext";
import BalanceInfo from "../../hooks/BalanceInfo";
import amountSeparatorFormatter from "../../util/amountSeparatorFormatter";

const AccountBalance = ({accountName, amount, info}) => {

	const handleClick = info(accountName);

	return <div className="flex relative h-full items-center px-[4.45%]">
		<div><balanceIcons.wallet fill="#676773"/></div>
		<div className="ml-[1.9vh] font-dm-sans text-[#101010]">{accountName}</div>
		<div className="flex mt-[0.015em]">
			<div className="ml-[2.99vh] mr-[0.75vh] font-dm-sans-bold">$</div>
			<div className="flex">
				<div className="font-dm-sans-bold mr-[1.9vh] text-[#101010]">{amountSeparatorFormatter(amount) ?? "N/A"}</div>
				<div className="cursor-pointer" onClick={handleClick}><balanceIcons.info fill="#9191A1" /></div>
			</div>
		</div>
	</div>
}

const Balance = ({infoPanel, setInfoPanel}) => {

	const { inmate } = useInmateContext();
	const { trustAccount, kiwiAccount } = BalanceInfo;

	const handleClickBuilder = (type) => () => {
		const _infoPanel = { ...infoPanel };
		Object.assign(_infoPanel, {infoType: type, showPanel: true});
		setInfoPanel(_infoPanel);
	}

	return <div className="mx-[4.5vh] h-[11%] bg-white mt-[1.04vh] rounded-[1.87vh]
	border border-[#E6E6E7] flex grid grid-cols-2 text-[3vh] py-[0.5em]
	min-w-[804px]">
		<div className="flex h-full items-center">
			<AccountBalance accountName={trustAccount.name} amount={inmate.trust} info={handleClickBuilder} />
		</div>
		<div className="flex border-l h-full items-center">
			<AccountBalance accountName={kiwiAccount.name} amount={inmate.balance} info={handleClickBuilder} />
		</div>
	</div>;
}

export default Balance;