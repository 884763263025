import React, {useEffect, useState} from "react";
import Transactions from "../components/transactionHistory/Transactions";
import TransactionFilterMenu from "../components/transactionHistory/TransactionFilterMenu";
import {TransactionFilterContextProvider} from "../context/TransactionFilterContext";
import {TransactionContextProvider} from "../context/TransactionContext";
import {useInmateContext} from "../context/InmateKiwiContext";
import {useTransitionContext} from "../context/TransitionContext";


const TransactionHistory = () => {

	const { inmate } = useInmateContext();
	const { transition } = useTransitionContext();
	const [openFilterMenu, setOpenFilterMenu] = useState(false);
	const [animation, setAnimation] = useState(' animate-component-slide-up');

	useEffect(() => {
		setAnimation(
			(transition) ?
				' animate-component-slide-down' :
				' animate-component-slide-up'
		)
	}, [transition])

	return <div className={"h-screen" + animation}>
		<TransactionFilterContextProvider>
			<TransactionContextProvider inmate={inmate}>
				<Transactions menuOpenSetter={setOpenFilterMenu} />
				{(openFilterMenu) && <TransactionFilterMenu setter={setOpenFilterMenu} />}
			</TransactionContextProvider>
		</TransactionFilterContextProvider>
	</div>;
}

export default TransactionHistory;