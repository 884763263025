import {createContext, useContext, useEffect, useState} from "react";
import * as uuid from 'uuid';

const NotificationContext = createContext({});

export const NotificationContextProvider = ({ children }) => {

	const [message, setMessage] = useState('');
	const [animation, setAnimation] = useState('');
	const [currentId, setCurrentId] = useState(undefined);
	const [notifications, setNotifications] = useState([]);

	const createError = (message) => {
		const newNotification = { id: uuid.v4(), message, type: "error" };
		setNotifications([...notifications, newNotification]);
	}

	const createSuccess = (message) => {
		const newNotification = { id: uuid.v4(), message, type: "success" };
		setNotifications([...notifications, newNotification]);
	}

	const nextNotificationInQueue = () => {
		return notifications[notifications.length-1];
	}

	const removeNotificationFromQueue = () => {
		const _notifications = [...notifications];
		const recentNotification = _notifications.pop();
		setNotifications(_notifications);
		return recentNotification;
	}

	const triggerAnimation = () => {
		setAnimation('animate-model-float-up');
		setTimeout(() => {
			removeNotificationFromQueue();
			setAnimation('animate-model-float-up opacity-0');
		}, 290);
	}

	const signal = {
		error: createError,
		success: createSuccess,
	}

	useEffect(() => {
		console.log(notifications);
	}, [notifications])

	return <NotificationContext.Provider value={{
		signal,
		message,
		setMessage,
		animation,
		currentId,
		setCurrentId,
		setAnimation,
		notifications,
		triggerAnimation,
		nextNotificationInQueue,
		removeNotificationFromQueue
	}}>
		{children}
	</NotificationContext.Provider>
}

export const useNotification = () => {
	const context = useContext(NotificationContext);
	return context;
}