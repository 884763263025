import React, { useState } from 'react';
import navIcons from '../../assets/nav-icons';
import {useLocation, useNavigate} from "react-router-dom";
import {useTransitionContext} from "../../context/TransitionContext";

const routeNameTranslator = {
	"Transfers": "transfers",
	"Transaction History": "transaction-history",
	"Subscriptions": "subscriptions",
}

const buildBaseRouteStyling = (name) => {
	const baseStyling = "fill-[#676773] text-[#686873] stroke-[#676773] py-[1.7vh] mb-[1.5vh] box-content px-[1.5vh] " +
		"ml-[2.95vh] mr-[2.2vh] cursor-pointer hover:rounded-lg hover:bg-[#038768] hover:text-white hover:fill-amber-50 " +
		"hover:stroke-white";
	return (name !== "Subscriptions") ? baseStyling : baseStyling.split("stroke-[#676773]").join("")
		.split("hove:stroke-white").join("");
}

const buildClickedRouteStyling = (name) => {
	const baseStyling = "fill-amber-50 text-white stroke-white py-[1.7vh] mb-[1.5vh] box-content px-[1.5vh] " +
		"ml-[2.95vh] mr-[2.2vh] rounded-lg bg-[#038768]";
	return (name !== "Subscriptions") ? baseStyling : baseStyling.split("stroke-white").join("");
}

const NavLogo = ({icon, name}) => {
	return <div className="flex ml-[3.75vh] py-[3.02vh] text-[3.02vh]">
		<div>{icon}</div>
		<div className="ml-[1.5vh] font-dm-sans">{name}</div>
	</div>
}

const navItemBuilder = ({closeMenu}) => (item) => {

	const [isElemClicked, setIsElemClicked] = useState(false);

	const elemStyling = (!isElemClicked) ? buildBaseRouteStyling(item.name) : buildClickedRouteStyling(item.name);

	const Elem = ({icon, name}) => {

		const handler = () => {
			setIsElemClicked(true);
			closeMenu(name);
		}

		return <div className={elemStyling} onClick={handler}>
			<div className="flex items-start text-[3.02vh]">
				{icon}
				<div className="ml-[1.9vh] font-dm-sans">{name}</div>
			</div>
		</div>
	}

	return <Elem key={item.key} icon={item.icon} name={item.name}></Elem>

}

const Bar = ({toggleMenu}) => {
	const navLogo = navIcons[0];
	const navRoutes = navIcons.slice(1);

	const navigate = useNavigate();
	const location = useLocation();
	const [currentPath] = useState(location.pathname);
	const [slideMenuAway, setSlideMenuAway] = useState(false);

	const { triggerTransition } = useTransitionContext();

	const closeMenuInFiveSecondsBuilder = (name) => (toggler) => {
		triggerTransition();
		setTimeout(() => {
			toggler(false);
			setTimeout(() => {
				if(!currentPath.includes(routeNameTranslator[name]) && routeNameTranslator[name])
					navigate(`/${routeNameTranslator[name]}`);
			}, 150)
		}, 300);
	}

	const closeMenu = (name=undefined) => {
		setSlideMenuAway(true);
		const closeMenuInFiveSeconds = closeMenuInFiveSecondsBuilder(name);
		closeMenuInFiveSeconds(toggleMenu);
	}

	const navItem = navItemBuilder({closeMenu});

	const baseBackgroundStyling = "h-screen w-screen absolute top-0 left-0";
	const baseMenuStyling = "bg-white absolute rounded-r-[3vh] h-screen w-[30%]";
	const backgroundStyling = baseBackgroundStyling.concat((!slideMenuAway) ? " animate-fade-in" : " animate-fade-out");
	const menuStyling = baseMenuStyling.concat((!slideMenuAway) ? " animate-slide-in" : " animate-slide-out pointer-events-none");
	return <div>
		<div className={backgroundStyling} onClick={closeMenu}/>
		<div className={menuStyling}>
			<div className="mb-[1.5vh]">
				<NavLogo icon={navLogo.icon} name={navLogo.name} />
			</div>
			<div>
				{navRoutes.map(navItem)}
			</div>
		</div>
	</div>
}

const OpenMenu = ({isOpen, setPageTitle, toggleMenu}) => {
	return <div className="bg-[#10101033] w-screen h-screen relative">
		<Bar isOpen={isOpen} setPageTitle={setPageTitle} toggleMenu={toggleMenu}/>
	</div>;
}

const SideBar = ({isOpen, setPageTitle, toggleMenu}) => {
	return (isOpen) && <OpenMenu isOpen={isOpen} setPageTitle={setPageTitle} toggleMenu={toggleMenu}/>;
}

export default SideBar;