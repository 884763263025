import {useState} from "react";
import Loading from "../shared/Loading";
import {useInmateContext} from "../../context/InmateKiwiContext";
import CancelSubscriptionPrompt from "./CancelSubscriptionPrompt";
import SubscriptionInfo from "./manager-components/SubscriptionInfo";
import {PaymentHistoryContextProvider, usePaymentHistoryContext} from "../../context/PaymentHistoryContext";
import ReactivateSubscriptionPrompt from "./ReactivateSubscriptionPrompt";

const PopUpContainer = ({subscription, managerSetter}) => {

	const { inmate } = useInmateContext();

	const [showText, setShowText] = useState(true);
	const [animation, setAnimation] = useState(" animate-model-drop");

	const [switchToCancel, setSwitchToCancel] = useState(false);

	const cancelInfoDimensions = "h-fit w-[55%]"
	const subscriptionInfoDimensions = "h-fit w-[54.93%]";
	const baseStyling = "absolute bg-white mx-auto z-40 " +
		"left-0 right-0 my-auto top-0 bottom-0 rounded-[4.3vh]";

	const toggleSwitcher = async () => {
		setSwitchToCancel(!switchToCancel);
		setShowText(false);
		setAnimation(
			(!switchToCancel) ?
			"animate-subscription-to-cancel" :
			"animate-cancel-to-subscription"
		)
		setTimeout(() => {
			setShowText(true);
		}, 310)
	}

	const closeOnClick = () => {
		setAnimation("animate-model-float-up");
		setTimeout(() => {
			managerSetter(false);
		}, 285)
	}

	const SubscriptionContent = () => {

		const { fetchStatus } = usePaymentHistoryContext();

		return <div>
			{
				(fetchStatus === "loading") ?
					(<div className="h-[85.2vh]">
						<Loading message="Retrieving Subscriptions..." />
					</div>) :
					((!switchToCancel) ?
						<SubscriptionInfo
							switchOnClick={toggleSwitcher}
							closeOnClick={closeOnClick}
						/> :
						<div className="mx-[5.15%] mt-[5.15%]">
							{(!subscription.charge.isCancelled) ?
								<CancelSubscriptionPrompt OnClick={toggleSwitcher} /> :
								<ReactivateSubscriptionPrompt OnClick={toggleSwitcher}/>
							}
						</div>)
			}
		</div>
	}

	return <div>
		<PaymentHistoryContextProvider inmate={inmate} subscription={subscription}>
			<div className={`${baseStyling} ${animation} ${(switchToCancel) ? cancelInfoDimensions : subscriptionInfoDimensions}`}>
				<div className={(showText) ? "opacity-100" : "opacity-0"}>
						<SubscriptionContent/>
				</div>
			</div>
		</PaymentHistoryContextProvider>
		<div className="z-30 absolute top-0 left-0 bg-[#101010] h-screen w-screen opacity-[0.2]"></div>
	</div>
}

export default PopUpContainer;