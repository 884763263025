import {createContext, useContext, useState} from "react";
import transactionFiltersTemplate from "../util/transactionFiltersTemplate";

const TransactionFilterContext = createContext({});

const TransactionFilterContextProvider = ({ children }) => {

	const [filters, setFilters] = useState(transactionFiltersTemplate);

	return <TransactionFilterContext.Provider value={{
		filters,
		setFilters,
	}}>
		{children}
	</TransactionFilterContext.Provider>
}

const useTransactionFilter = () => {
	const context = useContext(TransactionFilterContext);
	return context;
}

export { useTransactionFilter, TransactionFilterContextProvider };
