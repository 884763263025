import {useEffect, useState} from "react";
import Loading from "../shared/Loading";
import {useSubscriptionContext} from "../../context/SubscriptionContext";
import SubscriptionContainer from "./manager-components/SubscriptionContainer";
import {useTransitionContext} from "../../context/TransitionContext";

const SubscriptionTitleHeaders = () => {
	return <div className="flex text-[2.05vh] text-[#9191A1] mt-[1.8vh] relative">
		<div className="ml-[0.65vh]">
			Subscription
		</div>
		<div className="absolute right-[2.05%] text-right">
			Next Payment
		</div>
		<div className="absolute left-[47.5%]">
			Amount
		</div>
	</div>
}

const SubscriptionManger = ({openPopUp, setSubscription}) => {

	const {
		data,
		fetchStatus,
		errorOnRetrieval,
		initialLoadStatus,
		currentlyFetchingSubscriptions,
	} = useSubscriptionContext();
	const { transition } = useTransitionContext();
	const [animation, setAnimation] = useState(' animate-component-slide-up');

	useEffect(() => {
		setAnimation(
			(transition) ?
				' animate-component-slide-down' :
				' animate-component-slide-up'
		)
	}, [transition]);

	useEffect(() => {
		if(initialLoadStatus === "success"){
			console.log("Initial load retrieved the following data, ", data);
		}

		if(
			initialLoadStatus === "error"
			|| fetchStatus === "error"
			|| (!currentlyFetchingSubscriptions && errorOnRetrieval)
		){

		}
	}, [initialLoadStatus, currentlyFetchingSubscriptions, fetchStatus]);

	return <div className={"mx-[5vh] bg-white rounded-[4.3vh] h-[73%] mt-[3vh] py-[2.85vh]" + animation}>
		<div className="font-dm-sans-medium text-[3vh] mx-[4vh]">
			Available subscriptions
		</div>
		<div className="mx-[4vh]">
			<SubscriptionTitleHeaders />
		</div>
		<div className="ml-[4.2vh] mr-[2.1vh] mt-[0.7vh] h-full">
			{(initialLoadStatus === "success") ?
				<SubscriptionContainer openPopUp={openPopUp} setSubscription={setSubscription}/> :
				<Loading message="Retrieving Subscriptions..." />
			}
		</div>
	</div>
}

export default SubscriptionManger;