import {useQuery} from "react-query";
import Loading from "../shared/Loading";
import {useEffect, useState} from "react";
import TransferAmount from "./TransferAmount";
import TransferDirection from "./TransferDirection";
import {useInmateContext} from "../../context/InmateKiwiContext";
import {useNotification} from "../../context/NotificationContext";
import {historyDateFormatter} from "../../util/dateFormatters";
import {useTransitionContext} from "../../context/TransitionContext";

const TransferTool = ({fromAccount, toAccount}) => {

	const { signal } = useNotification();
	const { inmate } = useInmateContext();
	const { transition } = useTransitionContext();
	const [amount, setAmount] = useState(0);
	const [animation, setAnimation] = useState(' animate-component-slide-up');

	const successMessage = () => {
		return `A transfer request of $${amount} was successfully created on ${historyDateFormatter(new Date())}.`
	}

	const performTransfer = async () => {
		if(amount <= 0){
			signal.error("Invalid request amount, please enter a value greater than zero.")
			return null;
		}

		const response = await inmate.api.inmate.requestKiwiCoin({
			transactorId: inmate.id,
			agencyId: inmate.agencyId,
			amount: amount,
		});

		signal.success(successMessage());
		return response;
	}

	const {
		status,
		refetch,
		isRefetching,
		isRefetchError
	} = useQuery('Transfer', performTransfer, { enabled: false });

	useEffect(() => {
		setAnimation(
			(transition) ?
			' animate-component-slide-down' :
			' animate-component-slide-up'
		)
	}, [transition])

	useEffect(() => {
		if(status === "error" || isRefetchError)
			signal.error("Error making transfer request, please try again later...");
	}, [status, isRefetchError])

	const TransferWidget = () => {
		return <div>
			<TransferDirection fromAccount={fromAccount} toAccount={toAccount}/>
			<hr className="mx-[4%] mt-[6.05vh]"/>
			<TransferAmount setter={setAmount} makeTransfer={refetch}/>
			<div className="w-full pb-[2.2vh]">
				<div className="text-[#9191A1] text-[2.7vh] font-dm-sans text-center">All transfers are final</div>
			</div>
		</div>
	}

	return <div className="w-screen h-screen">
		<div className={"relative w-[47%] h-[66%] mx-auto min-h-[307px] min-w-[397px]" + animation}>
			<div className="mt-[2.1vh] mb-[1.7vh]">
				<div className="relative font-dm-sans-medium text-[3.75vh] text-center text-[#101010]">Transfer</div>
			</div>
			<div className="bg-white rounded-[4.3vh] h-[91.5%]">
				{(status === "loading" || isRefetching) ? <Loading
					message="Processing request..."
				/> : <TransferWidget /> }
			</div>
		</div>
	</div>
}

export default TransferTool;