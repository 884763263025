import transferIcons from "../../assets/transfer-icons";

const TransferDirection = ({fromAccount, toAccount}) => {

	const fromClass = 'mt-[6.02vh]';
	const toClass = 'float-right mt-[6.02vh]';
	const arrowClass = 'absolute left-[47.5%] mt-[6.7vh]';

	return <div className="flex text-[2.7vh] px-[4.5%]">
		<div className="w-full">
			<div className={fromClass}>
				<div className="mb-[0.7vh] text-[#9191A1]">From</div>
				<div>{fromAccount.name}</div>
			</div>
		</div>
		<div className={arrowClass}>
			<transferIcons.rightArrow stroke="#038768"/>
		</div>
		<div className="w-full">
			<div className={toClass}>
				<div className="mb-[0.7vh] text-[#9191A1]">To</div>
				<div>{toAccount.name}</div>
			</div>
		</div>
	</div>
}

export default TransferDirection;