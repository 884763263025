import {createContext, useContext, useRef} from "react";
import {useQuery} from "react-query";

const SubscriptionContext = createContext({});

const SubscriptionContextProvider = ({children, inmate}) => {

	const initialLoad = useRef(true);
	const subscriptions = useRef([])
	const nextToken = useRef(undefined);

	const extractToken = ({nextPage}) => {
		return (nextPage) ? nextPage[0].nextToken : undefined;
	}

	const setRefs = (_subscriptions, response) => {
		subscriptions.current = [...subscriptions.current, ..._subscriptions];
		nextToken.current = extractToken(response);
	}

	const fetchPurchases = async () => {
		return inmate.api.inmate.activePurchases({
			id: inmate.username,
			nextPage: nextToken.current
		});
	}

	const fetchChargeInfo = async (kiwiSubscriptionId) => {
		return inmate.api.kiwiPay.kiwiSubscription.getSubscriptionChargeInfo({
			userId: inmate.id,
			kiwiSubscriptionId: kiwiSubscriptionId,
		});
	}

	const fetchSubscriptionInfo = async () => {

		const subscriptionResponse = await fetchPurchases();
		const _subscriptions = subscriptionResponse.items.filter(purchase => purchase.kiwiSubscriptionId);

		// todo: Remove this with a command that performs a batch get
		for(const subscription of _subscriptions){
			const chargeInfo = await fetchChargeInfo(subscription.kiwiSubscriptionId);
			Object.assign(subscription, { charge: chargeInfo });
		}

		setRefs(_subscriptions, subscriptionResponse);

		console.log(subscriptions.current);

	}

	function nameComparison(a, b) {
		if (a.product.name < b.product.name) return -1;
		if (a.product.name > b.product.name) return 1;
		return 0;
	}

	const sortSubscriptions = () => {
		subscriptions.current.sort(nameComparison);
		subscriptions.current = [
			...subscriptions.current.filter(subscription => subscription.active).sort(nameComparison),
			...subscriptions.current.filter(subscription => !subscription.active).sort(nameComparison),
		]
	}

	const getInitialSubscriptions = async () => {

		console.log(initialLoad.current);

		if(!initialLoad.current && !nextToken.current)
			return { subscriptions: subscriptions.current, nextToken: nextToken.current };

		initialLoad.current = false;

		do{
			await fetchSubscriptionInfo();
		} while(subscriptions.current.length < 6 && nextToken.current);

		sortSubscriptions();

		return { subscriptions: subscriptions.current, nextToken: nextToken.current };

	}

	const retrieveMoreSubscriptions = async () => {

		const originalSubscriptionAmount = subscriptions.current.length;

		do {

			if(!nextToken.current) break;
			await fetchSubscriptionInfo();

		} while(subscriptions.current.length - originalSubscriptionAmount < 6 && nextToken.current);

		sortSubscriptions();

		return { subscriptions: subscriptions.current, nextToken: nextToken.current };

	}

	const {
		status: fetchStatus,
		refetch: fetchMoreSubscriptions,
		isRefetchError: errorOnRetrieval,
		isRefetching: currentlyFetchingSubscriptions,
	} = useQuery('FetchMoreSubscriptions', retrieveMoreSubscriptions, { enabled: false });
	const { data, status: initialLoadStatus } = useQuery('InitializeSubscriptions', getInitialSubscriptions);

	return <SubscriptionContext.Provider value={{
		data,
		nextToken,
		fetchStatus,
		subscriptions,
		errorOnRetrieval,
		initialLoadStatus,
		fetchMoreSubscriptions,
		currentlyFetchingSubscriptions,
	}}>
		{children}
	</SubscriptionContext.Provider>

}

const useSubscriptionContext = () => {
	const context = useContext(SubscriptionContext);
	return context;
}

export { useSubscriptionContext, SubscriptionContextProvider }