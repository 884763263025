/*
 *********************************DEV W/INMATE COGNITO CREDENTIALS***************************************
 */
const aws_mobile = {
	API: {
		graphql_endpoint: "https://xzyq7khn4jdeblkvyrgvtdzcou.appsync-api.us-east-1.amazonaws.com/graphql"
	},
	aws_appsync_authenticationType: "AWS_COGNITO_USER_POOLS",
	Auth: {
		region: "us-east-1",
		userPoolId: "us-east-1_aG30EqNY5",
		identityPoolId: "us-east-1:9c37106e-5977-4b41-a15a-d468a8fe5aa1",
		userPoolWebClientId: "fbp3d46laauisbsersr3ir51p",
		cognitoDomain: "https://kiwiauth2.auth.us-east-1.amazoncognito.com",
	},
	Storage: {
		messageImgs: {
			bucket: "kiwichatappstack-kiwichatmsgimgsaa0ddb06-13l9tjtqowsin",
			region: "us-east-1",
		},
		grievanceAndForm: {
			bucket: "kiwichatappstack-kiwichatgrievanceandformfilesed8-1rbguxcnyhskk",
			region: "us-east-1",
		},
		bulletins: {
			bucket: "kiwichatappstack-kiwichatbulletinfilesfea9a418-14jqkmr1ykvti",
			region: "us-east-1",
		},
	},
	kiwi: {
		refresh_token: "",
		logLevel: 'DEBUG',
		clientType: 'INMATE_ANDROID',
	},
	environment:{
		env: "DEV",
	},
};

export default aws_mobile;