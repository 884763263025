import ScrollHeight from "../../../hooks/ScrollHeight";
import Retrieving from "../../shared/Retrieving";
import {useSubscriptionContext} from "../../../context/SubscriptionContext";
import SubscriptionItem from "./SubscriptionItem";

const SubscriptionContainer = ({openPopUp, setSubscription}) => {

	const {
		fetchStatus,
		subscriptions,
		fetchMoreSubscriptions,
		currentlyFetchingSubscriptions,
	} = useSubscriptionContext();

	const handleScroll = (e) => {
		const { hitBottom } = ScrollHeight(e);
		if(hitBottom) fetchMoreSubscriptions();
	}

	return <div
		style={{scrollbarGutter: "stable"}}
		className="overflow-y-scroll h-[87.5%] w-full"
		onScroll={handleScroll}
	>
		{
			subscriptions.current.map((subscription, index) => <SubscriptionItem
				subscription={subscription}
				openManager={{openPopUp, setSubscription}}
				key={subscription.kiwiSubscriptionId}
				lastItem={subscriptions.current.length === index + 1}
			/>)
		}
		{
			(currentlyFetchingSubscriptions || fetchStatus === "loading")
			&& <div className="flex w-full justify-center mt-[2.7vh]"><Retrieving/></div>
		}
	</div>

}

export default SubscriptionContainer;