const historyIcons = {
	TRANSFER: ({bgFill, arrowFill}) => {
		return <svg
			width="16" height="16"
			viewBox="0 0 16 16" fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="relative w-[1em] h-[1em] top-[0.2em] bottom-[0.2em]"
		>
			<rect width="16" height="16" rx="5.33333" fill={bgFill}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M8.4293 8.3012C8.62457 8.10594 8.94115
			8.10594 9.13641 8.3012L10.6241 9.78886C10.8193 9.98412 10.8193 10.3007 10.6241
			10.496L9.13641 11.9836C8.94115 12.1789 8.62457 12.1789 8.4293 11.9836C8.23404
			11.7883 8.23404 11.4718 8.4293 11.2765L9.01236 10.6934L5.28194 10.6934C5.0058
			10.6934 4.78194 10.4696 4.78194 10.1934C4.78194 9.9173 5.0058 9.69345 5.28194
			9.69345L9.11444 9.69345L8.4293 9.00831C8.23404 8.81305 8.23404 8.49647 8.4293
			8.3012ZM6.72447 7.71411C6.52921 7.90937 6.21263 7.90937 6.01737 7.71411L4.52972
			6.22646C4.33445 6.0312 4.33445 5.71461 4.52972 5.51935L6.01737 4.0317C6.21263
			3.83644 6.52921 3.83644 6.72447 4.0317C6.91974 4.22696 6.91974 4.54355 6.72447
			4.73881L6.09033 5.37295L10.1117 5.37295C10.3879 5.37295 10.6117 5.59681 10.6117
			5.87295C10.6117 6.14909 10.3879 6.37295 10.1117 6.37295L6.09042 6.37295L6.72447
			7.007C6.91974 7.20226 6.91974 7.51885 6.72447 7.71411Z" fill={arrowFill}/>
		</svg>
	},
	PURCHASE: ({bgFill, arrowFill}) => {
		return <svg
			width="32" height="32"
			viewBox="0 0 32 32" fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="relative w-[2.05em] h-[2.05em]"
		>
			<rect width="32" height="32" rx="10.6667" fill={bgFill}/>
			<path d="M9.5 21.4998H8V10.4998H15.8L15 11.2898C14.8117 11.4768 14.7054 11.7309 14.7044
			11.9963C14.7035 12.2616 14.808 12.5165 14.995 12.7048C15.182 12.8931 15.4361 12.9994
			15.7015 13.0004C15.9668 13.0013 16.2217 12.8968 16.41 12.7098L18.91 10.2098C19.0037 10.1169
			19.0781 10.0063 19.1289 9.88439C19.1797 9.76253 19.2058 9.63183 19.2058 9.49982C19.2058
			9.3678 19.1797 9.2371 19.1289 9.11524C19.0781 8.99338 19.0037 8.88278 18.91 8.78982L16.41
			6.28982C16.2226 6.10357 15.9692 5.99902 15.705 5.99902C15.4408 5.99902 15.1874 6.10357
			15 6.28982C14.9063 6.38278 14.8319 6.49338 14.7811 6.61524C14.7303 6.7371 14.7042 6.8678
			14.7042 6.99982C14.7042 7.13183 14.7303 7.26253 14.7811 7.38439C14.8319 7.50625 14.9063
			7.61685 15 7.70982L15.79 8.49982H7C6.73478 8.49982 6.48043 8.60517 6.29289 8.79271C6.10536
			8.98025 6 9.2346 6 9.49982V22.4998C6 22.765 6.10536 23.0194 6.29289 23.2069C6.48043 23.3945
			6.73478 23.4998 7 23.4998H9.5C9.76522 23.4998 10.0196 23.3945 10.2071 23.2069C10.3946 23.0194
			10.5 22.765 10.5 22.4998C10.5 22.2346 10.3946 21.9802 10.2071 21.7927C10.0196 21.6052 9.76522
			21.4998 9.5 21.4998ZM25 8.49982H22.5C22.2348 8.49982 21.9804 8.60517 21.7929 8.79271C21.6054
			8.98025 21.5 9.2346 21.5 9.49982C21.5 9.76503 21.6054 10.0194 21.7929 10.2069C21.9804 10.3945
			22.2348 10.4998 22.5 10.4998H24V21.4998H15.63L16.42 20.7098C16.5137 20.6169 16.5881 20.5063 16.6389
			20.3844C16.6897 20.2625 16.7158 20.1318 16.7158 19.9998C16.7158 19.8678 16.6897 19.7371 16.6389
			19.6152C16.5881 19.4934 16.5137 19.3828 16.42 19.2898C16.2326 19.1036 15.9792 18.999 15.715
			18.999C15.4508 18.999 15.1974 19.1036 15.01 19.2898L12.51 21.7898C12.4163 21.8828 12.3419
			21.9934 12.2911 22.1152C12.2403 22.2371 12.2142 22.3678 12.2142 22.4998C12.2142 22.6318 12.2403
			 22.7625 12.2911 22.8844C12.3419 23.0063 12.4163 23.1169 12.51 23.2098L15.01 25.7098C15.1983
			 25.8968 15.4532 26.0013 15.7185 26.0004C15.9839 25.9994 16.238 25.8931 16.425 25.7048C16.612
			 25.5165 16.7165 25.2616 16.7156 24.9963C16.7146 24.7309 16.6083 24.4768 16.42 24.2898L15.63
			 23.4998H25C25.2652 23.4998 25.5196 23.3945 25.7071 23.2069C25.8946 23.0194 26 22.765 26 22.4998V9.49982C26
			 9.2346 25.8946 8.98025 25.7071 8.79271C25.5196 8.60517 25.2652 8.49982 25 8.49982Z" fill={arrowFill}/>
		</svg>

	},
	EXCHANGE: ({bgFill, arrowFill}) => {
		return <svg
			width="32" height="32"
			viewBox="0 0 32 32" fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="relative w-[2.05em] h-[2.05em]"
		>
			<rect width="32" height="32" rx="10.6667" fill={bgFill}/>
			<path d="M25.7099 13.2902L21.7099 9.29019C21.6167 9.19695 21.506 9.12299 21.3841 9.07253C21.2623
			9.02207 21.1318 8.99609 20.9999 8.99609C20.7336 8.99609 20.4782 9.10188 20.2899 9.29019C20.1016
			9.47849 19.9958 9.73388 19.9958 10.0002C19.9958 10.2665 20.1016 10.5219 20.2899 10.7102L22.5899
			13.0002H10.9999C10.7347 13.0002 10.4803 13.1055 10.2928 13.2931C10.1053 13.4806 9.9999 13.735
			9.9999 14.0002C9.9999 14.2654 10.1053 14.5198 10.2928 14.7073C10.4803 14.8948 10.7347 15.0002
			10.9999 15.0002H24.9999C25.1973 14.9992 25.39 14.9398 25.5538 14.8295C25.7175 14.7191 25.8449
			14.5628 25.9199 14.3802C25.9965 14.1981 26.0174 13.9974 25.98 13.8034C25.9426 13.6094 25.8487
			13.4308 25.7099 13.2902ZM20.9999 17.0002H6.9999C6.80247 17.0012 6.60975 17.0606 6.44603 17.1709C6.28231
			17.2812 6.15492 17.4376 6.0799 17.6202C6.00332 17.8023 5.98239 18.003 6.01977 18.197C6.05714 18.391
			6.15114 18.5696 6.2899 18.7102L10.2899 22.7102C10.3829 22.8039 10.4935 22.8783 10.6153 22.9291C10.7372
			22.9798 10.8679 23.006 10.9999 23.006C11.1319 23.006 11.2626 22.9798 11.3845 22.9291C11.5063 22.8783
			11.6169 22.8039 11.7099 22.7102C11.8036 22.6172 11.878 22.5066 11.9288 22.3848C11.9796 22.2629
			12.0057 22.1322 12.0057 22.0002C12.0057 21.8682 11.9796 21.7375 11.9288 21.6156C11.878 21.4937
			11.8036 21.3831 11.7099 21.2902L9.4099 19.0002H20.9999C21.2651 19.0002 21.5195 18.8948 21.707 18.7073C21.8945
			18.5198 21.9999 18.2654 21.9999 18.0002C21.9999 17.735 21.8945 17.4806 21.707 17.2931C21.5195
			17.1055 21.2651 17.0002 20.9999 17.0002Z" fill={arrowFill}/>
		</svg>

	},
	filter: ({fill, onClick}) => {
		return <svg
			width="24" height="24"
			viewBox="0 0 24 24" fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
			className="relative w-[1.6em] h-[1.6em] bottom-[0.1em]"
		>
			<path d="M4.21263 5.61C6.54385 8.59 9.99044 13 9.99044 13V18C9.99044
			19.1 10.8948 20 12.0001 20C13.1054 20 14.0098 19.1 14.0098 18V13C14.0098
			13 17.4564 8.59 19.7876 5.61C20.3001 4.95 19.8278 4 18.9837 4H5.00645C4.17243
			4 3.70016 4.95 4.21263 5.61Z" fill={fill}/>
		</svg>
	},
}

export default historyIcons;