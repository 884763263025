const balanceIcons = {

	wallet: ({fill}) => {
		return <svg
			width="13"
			height="12"
			className="relative w-[1em] h-[1em] bottom-[0.07em] shrink-0"
			viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 1.33333V9.83333C0 10.408 0.228273 10.9591 0.634602 11.3654C1.04093 11.7717
		1.59203 12 2.16667 12H10.1667C10.7413 12 11.2924 11.7717 11.6987 11.3654C12.1051 10.9591
		12.3333 10.408 12.3333 9.83333V4.16667C12.3333 3.67892 12.1688 3.20543 11.8663 2.82278C11.5639
		2.44012 11.1412 2.17067 10.6667 2.058V1.5C10.6667 1.30302 10.6279 1.10796 10.5525 0.925975C10.4771
		0.743986 10.3666 0.578628 10.2273 0.43934C10.088 0.300052 9.92268 0.189563 9.74069 0.114181C9.5587
		0.0387987 9.36365 0 9.16667 0H1.5C1.13104 1.11471e-05 0.77503 0.136005 0.500028 0.381981C0.225027
		0.627957 0.0503287 0.96666 0.00933329 1.33333H0ZM1.5 2C1.36739 2 1.24021 1.94732 1.14645 1.85355C1.05268
		1.75979 1 1.63261 1 1.5C1 1.36739 1.05268 1.24021 1.14645 1.14645C1.24021 1.05268 1.36739 1 1.5
		1H9.16667C9.29927 1 9.42645 1.05268 9.52022 1.14645C9.61399 1.24021 9.66667 1.36739 9.66667 1.5V2H1.5ZM8.83333
		6.66667H10.1667C10.2993 6.66667 10.4265 6.71935 10.5202 6.81311C10.614 6.90688 10.6667 7.03406 10.6667
		7.16667C10.6667 7.29928 10.614 7.42645 10.5202 7.52022C10.4265 7.61399 10.2993 7.66667 10.1667
		7.66667H8.83333C8.70072 7.66667 8.57355 7.61399 8.47978 7.52022C8.38601 7.42645 8.33333 7.29928
		8.33333 7.16667C8.33333 7.03406 8.38601 6.90688 8.47978 6.81311C8.57355 6.71935 8.70072 6.66667 8.83333 6.66667Z" fill={fill}/>
		</svg>
	},

	info: ({fill}) => {
		return <svg
			width="24" height="24"
			viewBox="0 0 24 24"
			fill="none"
			className="relative w-[1.3em] h-[1.3em] top-[0.075em]"
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="12" cy="12" r="9" stroke={fill} strokeWidth="2"/>
			<path d="M12.0098 8.97792C11.6831 8.97792 11.4125 8.87992 11.1978 8.68392C10.9925
		8.48792 10.8898 8.24059 10.8898 7.94192C10.8898 7.64326 10.9925 7.40059 11.1978
		7.21392C11.4125 7.01792 11.6831 6.91992 12.0098 6.91992C12.3365 6.91992 12.6025
		7.01792 12.8078 7.21392C13.0225 7.40059 13.1298 7.64326 13.1298 7.94192C13.1298
		8.24059 13.0225 8.48792 12.8078 8.68392C12.6025 8.87992 12.3365 8.97792 12.0098
		8.97792ZM11.1138 16.9999V10.0559H12.9058V16.9999H11.1138Z" fill={fill}/>
		</svg>
	},

	dollarSign: ({fill}) => {
		return <svg width="9" height="40" viewBox="0 0 9 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.64 26.218V25.14C2.72533 25.0373 1.99267 24.7153 1.442 24.174C0.891333
			23.6327 0.611333 22.9233 0.602 22.046H2.17C2.17933 22.4847 2.31 22.872 2.562 23.208C2.814
			23.5347 3.17333 23.754 3.64 23.866V20.492C3.56533 20.4733 3.486 20.45 3.402 20.422C3.32733
			20.394 3.248 20.366 3.164 20.338C2.408 20.086 1.83867 19.7593 1.456 19.358C1.08267 18.9567
			0.896 18.4293 0.896 17.776C0.886667 17.0107 1.134 16.3853 1.638 15.9C2.142 15.4147 2.80933
			15.13 3.64 15.046V13.926H4.592V15.06C5.404 15.1533 6.05733 15.4473 6.552 15.942C7.04667
			16.4367 7.29867 17.0573 7.308 17.804H5.74C5.73067 17.496 5.628 17.2067 5.432 16.936C5.24533
			16.656 4.96533 16.4647 4.592 16.362V19.358C4.66667 19.386 4.74133 19.414 4.816 19.442C4.89067
			19.4607 4.96533 19.484 5.04 19.512C5.52533 19.6707 5.95933 19.862 6.342 20.086C6.72467 20.31
			7.028 20.604 7.252 20.968C7.48533 21.332 7.602 21.794 7.602 22.354C7.602 22.8207 7.49 23.2593
			7.266 23.67C7.042 24.0713 6.706 24.4073 6.258 24.678C5.81 24.9487 5.25467 25.1073 4.592
			25.154V26.218H3.64ZM2.436 17.65C2.436 18.0233 2.54333 18.3127 2.758 18.518C2.97267 18.714
			3.26667 18.8867 3.64 19.036V16.32C3.28533 16.3853 2.996 16.5347 2.772 16.768C2.548 16.992
			2.436 17.286 2.436 17.65ZM6.034 22.452C6.034 21.9947 5.89867 21.6493 5.628 21.416C5.35733
			21.1733 5.012 20.9727 4.592 20.814V23.894C5.04933 23.838 5.404 23.6793 5.656 23.418C5.908
			23.1567 6.034 22.8347 6.034 22.452Z" fill={fill}/>
		</svg>


	},

	CLOSE: ({fill}) => {
		return <svg
			width="24" height="24"
			viewBox="0 0 24 24"
			fill="none" xmlns="http://www.w3.org/2000/svg"
			className="relative w-[1.1em] h-[1.1em] top-[0.25em]"
		>
			<path d="M18.875 6.50923L13.3842 12L18.875 17.4908L17.4908 18.875L12 13.3842L6.50923
			18.875L5.125 17.4908L10.6158 12L5.125 6.50923L6.50923 5.125L12 10.6158L17.4908 5.125L18.875
			6.50923Z" fill={fill ?? "#101010"}/>
		</svg>

	}
}

export default balanceIcons;