export const transactionDateFormatter = (date) => {
	const dateItems = date.split("/").filter((val, index) => index !== 1);
	const _date = dateItems.map((val, index) => {
		return (index === 1) ? val.slice(val.length-2) : val;
	})
	return _date.join("/");
}

export const historyDateFormatter = (date=undefined) => {
	if(!date) return '--/--/----';
	const dateItems = new Date(date).toISOString().slice(0, 10).split("-");
	return `${dateItems[1]}/${dateItems[2]}/${dateItems[0]}`;
}

export const dateSplitter = (createdAt) => {
	const dateItems = createdAt.split("T");
	const nonFormattedDate = dateItems[0].split("-")
	const date = `${nonFormattedDate[1]}/${nonFormattedDate[2]}`;

	const nonFormattedTime = dateItems[1].split(":");
	const hour = nonFormattedTime[0] > 12 ? nonFormattedTime[0]-12 : parseInt(nonFormattedTime[0]);
	const minute = nonFormattedTime[1];
	const meridiem = nonFormattedTime[0] >= 12 ? "PM" : "AM";
	const time = `${hour}:${minute} ${meridiem}`;

	return {date, time}
}

const formatters = {
	transactionDateFormatter,
	historyDateFormatter,
	dateSplitter,
}

export default formatters;