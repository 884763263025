import {useInmateContext} from "../../context/InmateKiwiContext";
import {useQuery} from "react-query";
import {useEffect} from "react";
import Loading from "../shared/Loading";
import {usePaymentHistoryContext} from "../../context/PaymentHistoryContext";
import {historyDateFormatter} from "../../util/dateFormatters";
import {useSubscriptionContext} from "../../context/SubscriptionContext";
import {useNotification} from "../../context/NotificationContext";

const CancelSubscriptionPrompt = ({OnClick}) => {

	const { signal } = useNotification();
	const { inmate } = useInmateContext();
	const { subscriptions } = useSubscriptionContext();
	const { subscription: { kiwiSubscriptionId, charge: { TTL } } } = usePaymentHistoryContext();

	const nextPaymentDate = historyDateFormatter((TTL) ? new Date(TTL * 1000) : TTL);

	const setCancellation = () => {
		const index = subscriptions.current.findIndex(item => item.kiwiSubscriptionId === kiwiSubscriptionId);
		Object.assign(subscriptions.current[index].charge, {isCancelled: true})
	}

	const setResults = async () => {
		setCancellation();
		await OnClick();
		signal.success("Subscription successfully cancelled.");
	}

	const cancelSubscription = async () => {
		const response = await inmate.api.kiwiPay.kiwiSubscription.cancel({
			kiwiSubscriptionId: kiwiSubscriptionId,
			cancelNow: false,
		});

		await setResults();
		return response;
	}

	const {
		status,
		refetch: triggerCancellation,
		isRefetching: isCancelling,
		isRefetchError: errorOnCancelling,
	} = useQuery('CancelSubscription', cancelSubscription, { enabled: false });

	const handleClick = () => {
		triggerCancellation().then(() => console.log("Subscription cancelled"));
	}

	useEffect(() => {
		if(status === "error" || errorOnCancelling){
			signal.error("Error cancelling subscription, please try again later...")
		}
	}, [status, errorOnCancelling]);

	const CancelPrompt = () => {
		return <div className="animate-fade-in">
			<div className="text-[4.53vh] font-dm-sans">Cancel Subscription</div>
			<div className="text-[2.643vh] font-dm-sans mt-[3vh]">
				<div>
					You will still be able to access content until {nextPaymentDate}.
				</div>
				<div>
					Do you want to continue to cancel this subscription?
				</div>
			</div>
			<div className="mt-[4.53vh] float-right">
				<div className="flex my-[4.53vh] w-fit">
					<div className="py-[2.26vh] px-[2.82vw] text-[3.02vh] font-dm-sans" onClick={handleClick}>
						Yes, cancel
					</div>
					<div className="py-[2.26vh] px-[2.82vw] bg-[#038768] rounded-xl" onClick={OnClick}>
						<div className="text-[#FFFFFF] text-[3.02vh] font-dm-sans">No, leave active</div>
					</div>
				</div>
			</div>
		</div>
	}

	const IsLoading = () => {
		return <div className="h-[40.5vh]">
			<Loading message="Cancelling subscription..."/>
		</div>
	}

	return <div>
		{(isCancelling || status === "loading") ? <IsLoading /> : <CancelPrompt/>}
	</div>
}

export default CancelSubscriptionPrompt;