const mockBalance = {
	trustAccount: {
		name: "Trust balance",
		amount: "12,410.00",
		info: () => {
			console.log("Trust Account")
		}
	},
	kiwiAccount: {
		name: "Kiwichat balance",
		amount: "2,109.00",
		info: () => {
			console.log("Kiwichat Account")
		}
	}
}

export default mockBalance;