import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import kiwiProvider from "./Provider";
import {KiwiSvc} from "kiwi-sdk";
import axios from "axios";
import aws_mobile from "../sdk-config";

const LoadSession = () => {

	const [isSessionLoading, setIsSessionLoading] = useState(true);
	const [kiwiAccount, setKiwiAccount] = useState({});
	const [inmateInfo, setInmateInfo] = useState({});
	const [session, setSession] = useState({});

	const [searchParams] = useSearchParams();

	useEffect(() => {

		const exchangeTokens = async (refreshToken) => {

			console.log(aws_mobile);

			const clientId = aws_mobile.Auth.userPoolWebClientId;
			const endpoint = `${aws_mobile.Auth.cognitoDomain}/oauth2/token`;

			const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
			const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;

			const response = await axios.post(endpoint, body, config).then(response => response.data);

			console.log(response);

			return response;
		}

		const queryInmateInfo = async (_session) => {
			const api = KiwiSvc(kiwiProvider);
			const userId = `I^${_session.idToken.payload["cognito:username"]}`;

			console.log(userId);

			const inmateInfo = await api.inmate.info({id: userId});
			const inmateAccount = await api.inmate.accountBalance({userId});

			return { inmateInfo, inmateAccount };
		}

		const establishSession = async () => {
			const currentSession = await kiwiProvider.auth.getCurrentSession();

			console.log("Session exist, sessions info: ", {
				session: currentSession,
				valid: currentSession.getAccessToken().getExpiration() > (new Date().getTime() / 1000),
			});

			console.log(await kiwiProvider.auth.getCurrentUser());

			if (currentSession) {
				const inmate = await queryInmateInfo(currentSession);
				console.log(inmate);
				await Promise.all([
					setInmateInfo(inmate.inmateInfo),
					setKiwiAccount(inmate.inmateAccount),
					setSession(currentSession),
					setIsSessionLoading(false),
				]);
			}
		}

		const hoistCredentials = async () => {
			try {
				await establishSession();
			} catch (e) {
				console.log("Establishing new session.");

				const refreshToken = searchParams.get("refreshToken");

				const {
					access_token: accessToken,
					id_token: idToken
				} = await exchangeTokens(refreshToken);

				const credentials = { idToken, accessToken, refreshToken, };

				try {
					kiwiProvider.auth.setUserCredentials(credentials, () => {});
					await establishSession();
				} catch (e) {
					console.log("error setting user credentials: ", e);
				}

			}
		}

		hoistCredentials().catch(e => {
			console.log('Error establishing session: ', e);
		})

	}, [])

	return {isSessionLoading, session, inmateInfo, kiwiAccount};

}

export default LoadSession;