const categoryFilterExtender = {
	"CHAT": [
		"IMAGE_MESSAGING",
		"VIDEO_MESSAGING",
		"VOICE_MESSAGING",
	],
	"VIDEOCHAT": [
		"VIDEO_CALL"
	],
	"MOVIES AND TV SHOWS": [
		"MOVIES",
		"TV_SHOWS"
	],
	"MUSIC": [
		"MUSIC",
	],
	"EBOOKS": [
		"EBOOKS",
	],
	"PODCASTS": [
		"PODCASTS",
	],
	"SERVICE": [
		"PRINTING_SERVICE",
	]
}

export default categoryFilterExtender;