import CurrencyInput from "react-currency-input-field";
import {useState} from "react";

const TransferAmount = ({setter, makeTransfer}) => {

	const [amount, setAmount] = useState(0);

	const handleSubmit = () => {
		setter(amount / 100);
		setTimeout(() => {
			makeTransfer();
		}, 150);
	}

	return <div className="mx-[2.1vw] mt-[4.6vh] h-full">
		<div className="text-[2.7vh] font-dm-sans-medium text-[#9191A1]">Enter Amount</div>
		<CurrencyInput
			name="transfer-amount"
			prefix="$"
			step={1}
			maxLength={9}
			placeholder="$0.00"
			transformRawValue={(rawValue) => {
				const regex = /[$,.]/g;
				const _rawValue = parseInt(rawValue.replaceAll(regex, ''));
				setAmount(_rawValue);
				console.log('Transfer Amount: ', _rawValue);
				return (_rawValue / 100).toFixed(2);
			}}
			className="mt-[1.04vh] text-[2.7vh] w-full h-[8.5vh] px-[1.83vw] py-[2.2vh] border border-[#DADAE2] rounded-[1.8vh]"
		/>
		<button className="bg-[#038768] w-full h-[8.5vh] mt-[5vh] mb-[1.4vh] rounded-[2.2vh]">
			<div className="font-dm-sans text-white text-[3.2vh]" onClick={handleSubmit}>
				Submit
			</div>
		</button>
	</div>

}

export default TransferAmount;