import FilterHeader from "./filter/FilterHeader";
import FilterOptions from "./filter/FilterOptions";
import {useTransactionFilter} from "../../context/TransactionFilterContext";
import FilterApplier from "./filter/FilterApplier";
import {useState} from "react";

const TransactionFilterMenu = ({setter}) => {

	const { filters } = useTransactionFilter();
	const { filterItems, transactionType } = filters;

	const [effect, setEffect] = useState('open');
	const [typeSelections, setTypeSelections] = useState({...transactionType});
	const [itemSelections, setItemSelections] = useState({...filterItems});

	const animations = {
		open: "animate-model-drop",
		close: "animate-model-float-up"
	}

	const baseStyling = "absolute bg-white mx-auto z-20 " +
		"left-0 right-0 my-[2%] top-0 bottom-0 rounded-[4.5vh] " +
		"h-auto w-[64%] ";

	const handleClick = () => {
		setEffect('close');
		setTimeout(() => {
			setter(false);
		}, 285);
	}

	return <div>
		<div className={baseStyling + animations[effect]}>
			<div className="mx-[4.37%] h-full">
				<div className="my-[4.5vh]">
					<FilterHeader handler={handleClick}/>
				</div>
				<div className="grid grid-cols-2 gap-x-[5%] h-[65.25%]">
					<FilterOptions
						title="Transaction Type"
						selections={typeSelections}
						setSelections={setTypeSelections}
					/>
					<FilterOptions
						title="Subscription/product"
						selections={itemSelections}
						setSelections={setItemSelections}
					/>
				</div>
				<div className="h-[19.12%]">
					<FilterApplier
						typeSelections={typeSelections}
						setTypeSelections={setTypeSelections}
						itemSelections={itemSelections}
						setItemSelections={setItemSelections}
						handleClick={handleClick}
					/>
				</div>
			</div>
		</div>
		<div className="z-10 absolute top-0 left-0 bg-[#101010] h-screen w-screen opacity-[0.2]"></div>
	</div>
}

export default TransactionFilterMenu;