import {createContext, useContext, useMemo, useState} from "react";
import {KiwiProvider, KiwiSvc} from "kiwi-sdk";
import config from "../sdk-config";
import formatName from "../util/formatName";

const InmateKiwiContext = createContext({});

const InmateKiwiContextProvider = ({ children, loadedData }) => {

	const { session, inmateInfo, kiwiAccount } = loadedData;

	const [provider] = useState(KiwiProvider({ config }));

	const inmate = useMemo(() => {

		const initializeSession = () => {
			const { displayId, id, location: { agencyId } } = inmateInfo;
			const { account: { firstName, lastName, account: { balance, trust, updatedTrustOn } } } = kiwiAccount;
			return {
				env: config.environment?.env,
				auth: provider.auth,
				api: KiwiSvc(provider),
				name: formatName(`${firstName} ${lastName}`),
				username: displayId,
				updatedTrustOn,
				session,
				balance,
				agencyId,
				trust,
				id,
			}
		}

		return initializeSession();

	}, [])

	return <InmateKiwiContext.Provider value={{inmate}}>
		{children}
	</InmateKiwiContext.Provider>

}

const useInmateContext = () => {
	const context = useContext(InmateKiwiContext);
	return context;
};

export { InmateKiwiContextProvider, useInmateContext };