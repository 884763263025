import ScrollHeight from "../../hooks/ScrollHeight";
import Retrieving from "../shared/Retrieving";
import {useTransactionContext} from "../../context/TransactionContext";
import Statement from "./statement-components/Statement";

const TransactionContainer = () => {

	const {
		fetchStatus,
		sanitizedTransactions,
		fetchMoreTransactions,
		currentlyFetchingTransactions,
	} = useTransactionContext();

	const handleScroll = (e) => {
		const { hitBottom } = ScrollHeight(e);
		if(hitBottom) fetchMoreTransactions();
	}

	return <div
		style={{scrollbarGutter: "stable"}}
		className="w-full h-[90%] overflow-y-auto"
		onScroll={handleScroll}
	>
		{
			sanitizedTransactions.map((activity, index) => <Statement
				key={activity.id}
				activity={activity}
				lastItem={sanitizedTransactions.length === index + 1}
			/>)
		}
		{
			(currentlyFetchingTransactions || fetchStatus === "loading")
			&& <div className="flex w-full justify-center mt-[2.7vh]"><Retrieving/></div>
		}
	</div>

}

export default TransactionContainer;