import {createContext, useContext, useState} from "react";

const TransitionContext = createContext({})

const TransitionContextProvider = ({ children }) => {

	const [transition, setTransition] = useState(false);

	const triggerTransition = () => {
		setTransition(true);
		setTimeout(() => {
			setTransition(false);
		}, 450)
	}

	return <TransitionContext.Provider value={{transition, triggerTransition}}>
		{children}
	</TransitionContext.Provider>
}

const useTransitionContext = () => {
	const context = useContext(TransitionContext);
	return context;
}

export  { TransitionContextProvider, useTransitionContext };