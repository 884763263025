import formatName from "./formatName";

const transferNameBuilder = (id, {payment, transactor, receiver}) => {

	if(payment.type === "TRUST"){
		return `Trust to KiwiChat`;
	}
	else if(transactor?.id !== id && receiver?.id === id){
		return `Transfer from ${formatName(`${transactor.firstName} ${transactor.lastName}`)}`;
	}
	else {
		return `Transfer to ${formatName(`${receiver.firstName} ${receiver.lastName}`)}`
	}

}

export default transferNameBuilder;