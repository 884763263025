import statusFilter from "./statusFilter";
import categoryFilter from "./categoryFilter";
import categoryFilterExtender from "./categoryFilterExtender";
import typeFilter from "./typeFilter";


const transactionFilterBuilder = (checkForStatus, checkForCategories, checkForType) => (transactions, filters) => {

	const statusModifier = {
		"Declined": "DENIED",
		"Pending": "PENDING",
		"Done": "APPROVED",
	}

	const statusFilterItems = Object.keys(filters.statuses)
		.filter(status => filters.statuses[status])
		.map(status => statusModifier[status]);

	const typeFilterItems = Object.keys(filters.transactionType)
		.filter(type => filters.transactionType[type])
		.map(type => type.toUpperCase());

	const categoryFilterItems = Object.keys(filters.filterItems)
		.filter(item => filters.filterItems[item])
		.map(item => categoryFilterExtender[item.toUpperCase()])
		.flat(1);

	const applyTypeFilter = (transaction) => typeFilter(transaction, typeFilterItems);
	const applyStatusFilter = (transaction) => statusFilter(transaction, statusFilterItems);
	const applyCategoryFilter = (transaction) => categoryFilter(transaction, categoryFilterItems);

	return transactions
		.filter(transaction => (checkForCategories) ? applyCategoryFilter(transaction) : true)
		.filter(transaction => (checkForStatus) ? applyStatusFilter(transaction) : true)
		.filter(transaction => (checkForType) ? applyTypeFilter(transaction) : true);

}

export default transactionFilterBuilder;