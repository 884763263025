import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useInmateContext} from "../../context/InmateKiwiContext";
import {useTransitionContext} from "../../context/TransitionContext";

const TopBar = ({menuToggle}) => {

	const minWidth = 'min-w-[852px]';
	const minHeight = 'min-h-[44px]';
	const fontSize = 'text-[2.75vh] ';
	const heightPercentage = 'h-[8.25%]';
	const horizontalMargin = 'px-[3.25%]';

	const openMenu = () => menuToggle(true);

	const location = useLocation();
	const { inmate } = useInmateContext();
	const { transition } = useTransitionContext();
	const [pageName, setPageName] = useState("Transfers");
	const [animation, setAnimation] = useState(' animate-fade-in-slow');

	useEffect(() => {
		setAnimation(
			(transition) ?
				' animate-fade-out opacity-0' :
				' animate-fade-in-slow'
		)
	}, [transition])

	useEffect(() => {
		if(location.pathname === "/"){
			setPageName("Transfers");
		}
		else{
			const path = location.pathname.substring(1).split("-").map(item => {
				return item[0].toUpperCase() + item.substring(1);
			}).join(" ");
			setPageName(path);
		}
	}, [location.pathname])

	const styling = `flex justify-between items-center flex-wrap bg-white
	py-3 ${horizontalMargin} drop-shadow-sm w-screen text-black ${fontSize}
	${heightPercentage} min-h-max:text-lg ${minHeight} ${minWidth} sticky`;

	return <div className={styling}>
		<div className="flex items-center">
			<svg
				viewBox="0 0 18 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="cursor-pointer w-[1em] h-[1em] relative bottom-[0.075em]"
				onClick={openMenu}>
				<path d="M1 12H17C17.55 12 18 11.55 18 11C18 10.45 17.55 10 17 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM1 7H17C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7ZM0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1Z" fill="#101010"/>
			</svg>
			<span className={"font-dm-sans ml-7" + animation}>{pageName}</span>
		</div>
		<div className="flex items-center">
			<div className="px-4 text-[#686873] font-dm-sans">{inmate.username}</div>
			<div className="relative pl-4 border-l border-[#DADAE2] font-dm-sans h-[2.7vh] flex items-center bottom-[0.1vh]">{inmate.name}</div>
		</div>
	</div>;
}

export default TopBar;