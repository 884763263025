import {dateSplitter} from "../../../util/dateFormatters";

const PaymentItem = ({transaction}) => {

	const { createdAt, amount, status } = transaction;

	const {date, time} = dateSplitter(createdAt);

	const statusTemplate = {
		"APPROVED": "Paid",
		"DECLINED": "Declined",
		"PENDING": "Pending",
	}

	return <div className="flex font-dm-sans mb-[3vh]">
		<div className="flex w-[41.5%] ">
			<div className="text-[2.643vh] mr-[0.73vh]">{date}</div>
			<div className="relative text-[2.275vh] text-[#9191A1] mt-[0.2vh]">{time}</div>
		</div>
		<div className="text-[2.643vh] w-[44.5%]">${amount}</div>
		<div className="text-[2.643vh] text-[#9191A1]">{statusTemplate[status]}</div>
	</div>
}

export default PaymentItem;