const transferIcons = {
	rightArrow: ({stroke}) => {
		return <svg
			width="24" height="24"
			viewBox="0 0 24 24"
			fill="none"
			className="relative w-[1.5em] h-[1.5em] top-[0.45em]"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M5 12L19 12" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12 5L19 12L12 19" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	},
	transfer: ({bgFill, arrowFill}) => {
		return <svg
			width="16" height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="16" height="16" rx="5.33333" fill={bgFill}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M8.42918 8.30095C8.62444 8.10568
			8.94103 8.10568 9.13629 8.30095L10.6239 9.7886C10.8192 9.98386 10.8192 10.3004
			10.6239 10.4957L9.13629 11.9834C8.94103 12.1786 8.62444 12.1786 8.42918 11.9834C8.23392
			11.7881 8.23392 11.4715 8.42918 11.2762L9.01187 10.6936L5.28181 10.6936C5.00567
			10.6936 4.78181 10.4697 4.78181 10.1936C4.78181 9.91742 5.00567 9.69356 5.28181
			9.69356L9.11469 9.69356L8.42918 9.00805C8.23392 8.81279 8.23392 8.49621 8.42918
			8.30095ZM6.72439 7.71313C6.52913 7.90839 6.21255 7.90839 6.01729 7.71313L4.52963
			6.22548C4.33437 6.03022 4.33437 5.71364 4.52963 5.51837L6.01729 4.03072C6.21255
			3.83546 6.52913 3.83546 6.72439 4.03072C6.91965 4.22599 6.91965 4.54257 6.72439
			4.73783L6.09093 5.3713L10.1116 5.3713C10.3878 5.3713 10.6116 5.59515 10.6116 5.8713C10.6116
			6.14744 10.3878 6.3713 10.1116 6.3713L6.08966 6.3713L6.72439 7.00602C6.91965
			7.20129 6.91965 7.51787 6.72439 7.71313Z" fill={arrowFill}/>
		</svg>

	}
}

export default transferIcons;