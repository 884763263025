import subscriptionIcons from "../../../assets/subscription-icons";
import {historyDateFormatter} from "../../../util/dateFormatters";
import amountFormatter from "../../../util/amountFormatter";

const SubscriptionItem = ({subscription, openManager, lastItem}) => {

	const { openPopUp, setSubscription } = openManager;
	const { product: { name, kiwiPrice, period }, charge: { TTL, isCancelled }, active } = subscription;

	const handleClick = () => {
		openPopUp(true);
		setSubscription(subscription);
	}

	return <div className="mr-[2.1vh] animate-fade-in">
		<div
			className="flex mb-[1.87vh] text-[2.64vh] px-[0.5vh] hover:animate-click-expand-in-out"
			onClick={handleClick}
		>
			<div className="flex w-[150%] py-[0.45vh] truncate">
				<div className="mr-[3vh]">
					<subscriptionIcons.playButtonManager/>
				</div>
				<div className="mt-[1.5vh] font-dm-sans truncate">{name}</div>
			</div>
			<div className="py-[1.95vh] w-[100%] font-dm-sans pl-[7%]">
				{amountFormatter(kiwiPrice)} <a className="text-[#9191A1]">per {period.toLowerCase()}</a>
			</div>
			<div className="py-[1.99vh] w-full">
				<div className="float-right text-[#9191A1] font-dm-sans mr-[2vh]">{
					historyDateFormatter((TTL && active && !isCancelled) ? new Date(TTL * 1000) : undefined)
				}</div>
			</div>
		</div>
		{(!lastItem) && <hr className="my-[2.24vh] stroke-[#F2F4F4]"/>}
	</div>
}

export default SubscriptionItem;