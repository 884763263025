import subscriptionIcons from "../../../assets/subscription-icons";
import PaymentHistory from "../history-components/PaymentHistory";
import SubscriptionHeader from "../history-components/SubscriptionHeader";
import {usePaymentHistoryContext} from "../../../context/PaymentHistoryContext";

const SubscriptionTitle = ({title, setter}) => {
	return <div className="flex text-[4.53vh]">
		<div><subscriptionIcons.playButton/></div>
		<div className="ml-[3vh] my-auto w-[300%] truncate">
			<a className="font-dm-sans">{title}</a>
		</div>
		<div className="w-full">
			<div className="float-right mt-[1.5vh] mr-[0.7vh]">
				<subscriptionIcons.close onClick={setter}/>
			</div>
		</div>
	</div>
}

const CancelButton = ({OnClick}) => {
	return <div className="text-[#9191A1] text-[2.643vh]" onClick={OnClick}>
		Cancel subscription
	</div>
}

const ReactivateButton = ({OnClick}) => {
	return <div className="text-[#9191A1] text-[2.643vh]" onClick={OnClick}>
		Reactivate subscription
	</div>
}

const SubscriptionInfo = ({
							  switchOnClick,
							  closeOnClick,
						  }) => {

	const { subscription } = usePaymentHistoryContext();

	return <div className="animate-fade-in">
		<div className="mt-[3.2vh] mx-[4.5vh]">
			<SubscriptionTitle title={subscription.product.name} setter={closeOnClick}/>
		</div>
		<div className="mt-[4.53vh] mx-[4.5vh]">
			<SubscriptionHeader />
		</div>
		<hr className="stroke-[#E6E6E7] mx-[4.5vh] mt-[3.02vh]" />
		<div className="mt-[4.53vh] mx-[4.5vh]">
			<PaymentHistory />
		</div>
		<div className="relative mx-auto w-fit mt-[7.55vh] mb-[4.53vh]">
			{
				(!subscription.charge.isCancelled) ?
				<CancelButton OnClick={switchOnClick}/> :
				<ReactivateButton OnClick={switchOnClick}/>
			}
		</div>
	</div>
}

export default SubscriptionInfo;