import {CircularProgress} from "@mui/material";

const Loading = ({message}) => {
	const textSize = `text-[3vh]`;
	return <div className={"flex justify-center items-center h-full " + textSize}>
		<div className="m-auto">
			<div className="flex justify-center items-center mb-[2.7vh]">
				<CircularProgress color="success" size="3.5em"/>
			</div>
			<div className="text-[#9191A1] font-dm-sans text-center">
				{message}
			</div>
		</div>
	</div>
}

export default Loading;