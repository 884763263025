import {CircularProgress} from "@mui/material";

const Retrieving = () => {
	return <div className="relative text-[2.7vh]">
		<CircularProgress
			color="inherit"
			size="3.25em"
		/>
	</div>
}

export default Retrieving;