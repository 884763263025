import Loading from "../../shared/Loading";
import scrollHeight from "../../../hooks/ScrollHeight";
import {usePaymentHistoryContext} from "../../../context/PaymentHistoryContext";
import PaymentItem from "./PaymentItem";

const HistoryHeaders = () => {
	return <div className="text-[#9191A1] text-[2.077vh] flex py-[0.85vh]">
		<div className="w-[87%]">Payment date</div>
		<div className="w-[93%]">Amount</div>
		<div className="w-[32%]">Status</div>
	</div>
}

const PaymentHistory = () => {

	const {
		fetchStatus,
		paymentHistory,
		fetchMoreSubscriptionTransactions,
		currentlyFetchingSubscriptionTransactions,
	} = usePaymentHistoryContext();

	const handleScroll = (e) => {
		const { hitBottom } = scrollHeight(e);
		if(hitBottom) fetchMoreSubscriptionTransactions();
	}

	return <div className="h-[35.4vh] w-full">
		<div className="font-dm-sans text-[2.643vh]">Payment history</div>
		<div className="mt-[1.5vh]">
			<HistoryHeaders/>
		</div>
		<div className="h-[80%] overflow-y-scroll" onScroll={handleScroll}>
			{
				(fetchStatus === "loading" || currentlyFetchingSubscriptionTransactions) ?
					<Loading message="Retrieving Subscription History..." /> :
					paymentHistory.current.map(transaction => <PaymentItem
						transaction={transaction}
						key={transaction.id}
					/>)
			}
		</div>
	</div>
}

export default PaymentHistory;