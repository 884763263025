import DateDisplay from "./DateDisplay";
import Amount from "./Amount";
import transferNameBuilder from "../../../util/transferNameBuilder";
import {useInmateContext} from "../../../context/InmateKiwiContext";
import nonTransferNameBuilder from "../../../util/nonTransferNameBuilder";
import {useEffect, useState} from "react";
import Title from "./Title";

const Statement = ({activity, lastItem}) => {

	const { inmate } = useInmateContext();
	const [clicked, setClicked] = useState(false);
	const [animation, setAnimation] = useState(" animate-fade-in opacity-1");

	const {createdAt, amount, transactionType, description} = activity;
	const _description = (description) ? description : "Transaction item";
	const _name = (transactionType === "TRANSFER") ? transferNameBuilder(inmate.id, activity) : nonTransferNameBuilder(activity);

	const handler = () => {
		const _clicked = !clicked;
		setClicked(_clicked);
		if(!_clicked){
			setTimeout(() => {
				setAnimation(" animate-fade-in opacity-1");
			}, 900)
		}
	}

	useEffect(() => {
		if(clicked) setAnimation(" animate-fade-out-fast opacity-0");
	}, [clicked])

	return <div className="pr-[1.7%] animate-fade-in">
		<div className="flex relative w-[100%]" onClick={handler}>
			<Title
				name={_name}
				description={_description}
				transactionType={transactionType}
				clicked={clicked}
			/>
			<div className={"absolute left-[51.25%] top-[0.19vh] z-20" + animation}>
				<DateDisplay createdAt={createdAt}/>
			</div>
			<div className={"absolute right-[6.5%] top-[2.05vh] z-20" + animation}>
				<Amount amount={amount}/>
			</div>
		</div>
		{(!lastItem) && <hr className="my-[2.24vh] stroke-[#F2F4F4]"/>}
	</div>;
}

export default Statement;