const subscriptionIcons = {
	playButton: () => {
		return <svg
			width="53" height="53"
			viewBox="8.5 4.5 53 53"
			fill="none" xmlns="http://www.w3.org/2000/svg"
			className="relative w-[2.199em] h-[2.199em] bottom-[0.05em]"
		>
			<rect x="10" y="6" width="50" height="50" rx="18.8" fill="#F7F8F9"/>
			<g filter="url(#filter0_f_2112_13991)">
				<rect x="26.4395" y="26.1577" width="7.6875" height="21.1406" rx="3.84375" fill="url(#paint0_linear_2112_13991)"/>
				<rect x="28.8763" y="24.75" width="21.101" height="7.6875" rx="3.84375" transform="rotate(30 28.8763 24.75)" fill="url(#paint1_linear_2112_13991)"/>
				<rect x="24.9995" y="41.9966" width="21.041" height="7.6875" rx="3.84375" transform="rotate(-30 24.9995 41.9966)" fill="url(#paint2_linear_2112_13991)"/>
				<circle cx="30.2833" cy="43.4531" r="3.84375" fill="url(#paint3_linear_2112_13991)"/>
				<circle cx="41.8145" cy="36.7285" r="3.84375" fill="url(#paint4_linear_2112_13991)"/>
				<circle cx="30.2833" cy="30.0015" r="3.84375" fill="#46B0BA"/>
			</g>
			<rect x="25.0012" y="17.248" width="10" height="27.5" rx="5" fill="url(#paint5_linear_2112_13991)"/>
			<rect x="28.1711" y="15.4189" width="27.4485" height="10" rx="5" transform="rotate(30 28.1711 15.4189)" fill="url(#paint6_linear_2112_13991)"/>
			<rect x="23.1286" y="37.855" width="27.3704" height="10" rx="5" transform="rotate(-30 23.1286 37.855)" fill="url(#paint7_linear_2112_13991)"/>
			<circle cx="30.0012" cy="39.7485" r="5" fill="url(#paint8_linear_2112_13991)"/>
			<circle cx="45.0015" cy="31" r="5" fill="url(#paint9_linear_2112_13991)"/>
			<circle cx="30.0012" cy="22.248" r="5" fill="#46B0BA"/>
			<defs>
				<filter id="filter0_f_2112_13991" x="0.555763" y="0.306252" width="71.0383" height="72.8421" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="12.925" result="effect1_foregroundBlur_2112_13991"/>
				</filter>
				<linearGradient id="paint0_linear_2112_13991" x1="30.2833" y1="26.1577" x2="30.2833" y2="47.2983" gradientUnits="userSpaceOnUse">
					<stop stopColor="#3EA9A9"/>
					<stop offset="1" stopColor="#784BF7"/>
				</linearGradient>
				<linearGradient id="paint1_linear_2112_13991" x1="28.8462" y1="28.6111" x2="48.4546" y2="28.941" gradientUnits="userSpaceOnUse">
					<stop stopColor="#03A882"/>
					<stop offset="1" stopColor="#A1A9E9"/>
				</linearGradient>
				<linearGradient id="paint2_linear_2112_13991" x1="32.0638" y1="46.0929" x2="45.1766" y2="46.3618" gradientUnits="userSpaceOnUse">
					<stop stopColor="#551CF6"/>
					<stop offset="1" stopColor="white"/>
				</linearGradient>
				<linearGradient id="paint3_linear_2112_13991" x1="33.1661" y1="41.5313" x2="26.92" y2="45.8555" gradientUnits="userSpaceOnUse">
					<stop stopColor="#8861F8"/>
					<stop offset="1" stopColor="#551CF6" stopOpacity="0"/>
				</linearGradient>
				<linearGradient id="paint4_linear_2112_13991" x1="38.9316" y1="39.6113" x2="45.6582" y2="34.8066" gradientUnits="userSpaceOnUse">
					<stop stopColor="#9F7FFB"/>
					<stop offset="1" stopColor="#E5DDFD"/>
				</linearGradient>
				<linearGradient id="paint5_linear_2112_13991" x1="30.0012" y1="17.248" x2="30.0012" y2="44.748" gradientUnits="userSpaceOnUse">
					<stop stopColor="#3EA9A9"/>
					<stop offset="1" stopColor="#784BF7"/>
				</linearGradient>
				<linearGradient id="paint6_linear_2112_13991" x1="28.132" y1="20.4415" x2="53.6388" y2="20.8706" gradientUnits="userSpaceOnUse">
					<stop stopColor="#03A882"/>
					<stop offset="1" stopColor="#A1A9E9"/>
				</linearGradient>
				<linearGradient id="paint7_linear_2112_13991" x1="32.318" y1="43.1836" x2="49.3753" y2="43.5333" gradientUnits="userSpaceOnUse">
					<stop stopColor="#551CF6"/>
					<stop offset="1" stopColor="white"/>
				</linearGradient>
				<linearGradient id="paint8_linear_2112_13991" x1="33.7512" y1="37.2485" x2="25.6262" y2="42.8735" gradientUnits="userSpaceOnUse">
					<stop stopColor="#7143F5"/>
					<stop offset="1" stopColor="#551CF6" stopOpacity="0"/>
				</linearGradient>
				<linearGradient id="paint9_linear_2112_13991" x1="41.2515" y1="34.75" x2="50.0015" y2="28.5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#C9B8FA"/>
					<stop offset="1" stopColor="#B9A1FC"/>
				</linearGradient>
			</defs>
		</svg>
	},
	playButtonManager: () => {
		return <svg
			width="40" height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="relative w-[2.25em] h-[2.25em] top-[0.1em]"
		>
			<rect y="0.5" width="40" height="40" rx="8" fill="#F7F8F9"/>
			<g filter="url(#filter0_f_2112_11852)">
				<rect x="13.1514" y="16.625" width="6.15" height="16.9125" rx="3.075" fill="url(#paint0_linear_2112_11852)"/>
				<rect x="15.1016" y="15.5" width="16.8808" height="6.15" rx="3.075" transform="rotate(30 15.1016 15.5)" fill="url(#paint1_linear_2112_11852)"/>
				<rect x="12" y="29.2979" width="16.8328" height="6.15" rx="3.075" transform="rotate(-30 12 29.2979)" fill="url(#paint2_linear_2112_11852)"/>
				<circle cx="16.2264" cy="30.4627" r="3.075" fill="url(#paint3_linear_2112_11852)"/>
				<circle cx="25.452" cy="25.0818" r="3.075" fill="url(#paint4_linear_2112_11852)"/>
				<circle cx="16.2264" cy="19.7" r="3.075" fill="#46B0BA"/>
			</g>
			<rect x="12" y="9.5" width="8" height="22" rx="4" fill="url(#paint5_linear_2112_11852)"/>
			<rect x="14.5361" y="8.03613" width="21.9588" height="8" rx="4" transform="rotate(30 14.5361 8.03613)" fill="url(#paint6_linear_2112_11852)"/>
			<rect x="10.502" y="25.9844" width="21.8963" height="8" rx="4" transform="rotate(-30 10.502 25.9844)" fill="url(#paint7_linear_2112_11852)"/>
			<circle cx="16" cy="27.5" r="4" fill="url(#paint8_linear_2112_11852)"/>
			<circle cx="28" cy="20.5" r="4" fill="url(#paint9_linear_2112_11852)"/>
			<circle cx="16" cy="13.5" r="4" fill="#46B0BA"/>
			<defs>
				<filter id="filter0_f_2112_11852" x="2.125" y="5.625" width="37.4707" height="38.9131" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="5.5" result="effect1_foregroundBlur_2112_11852"/>
				</filter>
				<linearGradient id="paint0_linear_2112_11852" x1="16.2264" y1="16.625" x2="16.2264" y2="33.5375" gradientUnits="userSpaceOnUse">
					<stop stopColor="#3EA9A9"/>
					<stop offset="1" stopColor="#784BF7"/>
				</linearGradient>
				<linearGradient id="paint1_linear_2112_11852" x1="15.0775" y1="18.5889" x2="30.7642" y2="18.8528" gradientUnits="userSpaceOnUse">
					<stop stopColor="#03A882"/>
					<stop offset="1" stopColor="#A1A9E9"/>
				</linearGradient>
				<linearGradient id="paint2_linear_2112_11852" x1="17.6515" y1="32.5749" x2="28.1417" y2="32.79" gradientUnits="userSpaceOnUse">
					<stop stopColor="#551CF6"/>
					<stop offset="1" stopColor="white"/>
				</linearGradient>
				<linearGradient id="paint3_linear_2112_11852" x1="18.5326" y1="28.9252" x2="13.5357" y2="32.3846" gradientUnits="userSpaceOnUse">
					<stop stopColor="#8861F8"/>
					<stop offset="1" stopColor="#551CF6" stopOpacity="0"/>
				</linearGradient>
				<linearGradient id="paint4_linear_2112_11852" x1="23.1457" y1="27.3881" x2="28.527" y2="23.5443" gradientUnits="userSpaceOnUse">
					<stop stopColor="#9F7FFB"/>
					<stop offset="1" stopColor="#E5DDFD"/>
				</linearGradient>
				<linearGradient id="paint5_linear_2112_11852" x1="16" y1="9.5" x2="16" y2="31.5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#3EA9A9"/>
					<stop offset="1" stopColor="#784BF7"/>
				</linearGradient>
				<linearGradient id="paint6_linear_2112_11852" x1="14.5048" y1="12.0542" x2="34.9103" y2="12.3975" gradientUnits="userSpaceOnUse">
					<stop stopColor="#03A882"/>
					<stop offset="1" stopColor="#A1A9E9"/>
				</linearGradient>
				<linearGradient id="paint7_linear_2112_11852" x1="17.8535" y1="30.2472" x2="31.4993" y2="30.527" gradientUnits="userSpaceOnUse">
					<stop stopColor="#551CF6"/>
					<stop offset="1" stopColor="white"/>
				</linearGradient>
				<linearGradient id="paint8_linear_2112_11852" x1="19" y1="25.5" x2="12.5" y2="30" gradientUnits="userSpaceOnUse">
					<stop stopColor="#8861F8"/>
					<stop offset="1" stopColor="#551CF6" stopOpacity="0"/>
				</linearGradient>
				<linearGradient id="paint9_linear_2112_11852" x1="25" y1="23.5" x2="32" y2="18.5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#9F7FFB"/>
					<stop offset="1" stopColor="#E5DDFD"/>
				</linearGradient>
			</defs>
		</svg>
	},
	close: ({onClick}) => {
		return <svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
			className="relative w-[1em] h-[1em]"
		>
			<path d="M18.875 6.50923L13.3842 12L18.875 17.4908L17.4908 18.875L12 13.3842L6.50923 18.875L5.125 17.4908L10.6158 12L5.125 6.50923L6.50923 5.125L12 10.6158L17.4908 5.125L18.875 6.50923Z" fill="#101010"/>
		</svg>
	}
}

export default subscriptionIcons;