import amountFormatter from "../../../util/amountFormatter";

const Amount = ({amount}) => {
	return <div className="inline-block">
		<div className="text-[2.7vh] font-dm-sans mr-0" style={{textAlign: "right"}}>{
			amountFormatter(amount)
		}</div>
	</div>;
}

export default Amount;