import AccountInfo from './../../hooks/AccountInfo';
import {useEffect, useState} from "react";
import BalanceIcons from "../../assets/balance-icons";
import {useInmateContext} from "../../context/InmateKiwiContext";
import {historyDateFormatter} from "../../util/dateFormatters";

const InfoPanel = ({infoPanel, setInfoPanel}) => {

	const { inmate } = useInmateContext();
	const { TrustAccount, KiwiAccount } = AccountInfo();

	const [info, setInfo] = useState({});
	const [fadeIn, setFadeIn] = useState(false);
	const [animation, setAnimation] = useState(' animate-filter-expand-out')

	const textStyling = "text-justify text-[2.47vh] font-dm-sans";
	const baseStyling = "relative w-fit h-fit bg-white mx-[23%] rounded-[4.3vh] px-[3.4%] py-[3.4%]";

	useEffect(() => {
		setInfo((infoPanel.infoType === "Trust Account") ? TrustAccount : KiwiAccount);
		setTimeout(() => {
			setFadeIn(true);
		}, 250)
	}, [])

	const handleClick = () => {
		setAnimation(" animate-filter-expand-in");
		setTimeout(() => {
			const _infoPanel = {...infoPanel};
			Object.assign(_infoPanel, {showPanel: false});
			setInfoPanel(_infoPanel);
		}, 225)
	}

	const trustDescription = () => {
		const updatedDate = (inmate.updatedTrustOn) ? historyDateFormatter(inmate.updatedTrustOn) : "N/A";
		return `The balance displayed is updated as of ${updatedDate}. Once your transfer ` +
			"request is submitted, your facility will look at the latest trust account " +
			"balance in order to transfer to your KiwiChat account."
	}

	const kiwiDescription = () => {
		return "Your KiwiChat account balance can be used for KiwiChat products and subscriptions."
	}

	const Disclaimer = () => {
		return <div className="mt-[2.1vh]">
			<div>Disclaimer: {info.disclaimer}</div>
		</div>
	}

	return <div className="w-[100%] h-screen">
		<div className="absolute top-0 left-0 bg-[#101010] h-screen w-screen opacity-[0.2]"/>
		<div className="w-screen h-screen flex justify-items-center items-center">
			<div className={baseStyling + animation}>
				<div className={(fadeIn) ? "animate-fade-in" : "opacity-0"}>
					<div className="flex text-[4.53vh] mb-[1.9vh] font-dm-sans-bold">
						<div className="w-full">
							{info.name ?? ""}
						</div>
						<div className="w-[25%]">
							<div className="relative float-right bottom-[0.25em]" onClick={handleClick}>
								<BalanceIcons.CLOSE/>
							</div>
						</div>
					</div>
					<div className={textStyling}>
						<div>{(infoPanel.infoType === "Trust Account") ? trustDescription() : kiwiDescription()}</div>
						{(info.disclaimer) && <Disclaimer/>}
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default InfoPanel;