const BalanceInfo = {
	trustAccount: {
		name: "Trust Account",
		info: () => {
			console.log("Trust Account")
			return ``;
		}
	},
	kiwiAccount: {
		name: "Kiwi Account",
		info: () => {
			console.log("Kiwichat Account")
			return ``;
		}
	}
}

export default BalanceInfo;