import React, { useState } from 'react';
import {useInmateContext} from "../context/InmateKiwiContext";
import PopUpContainer from "../components/subscriptions/PopUpContainer";
import {SubscriptionContextProvider} from "../context/SubscriptionContext";
import SubscriptionManger from "../components/subscriptions/SubscriptionManger";

const Subscriptions = () => {

	const { inmate } = useInmateContext();
	const [popUp, setPopUp] = useState(false);
	const [subscription, setSubscription]= useState({})

	return <div className="h-full">
		<SubscriptionContextProvider inmate={inmate}>
			<SubscriptionManger openPopUp={setPopUp} setSubscription={setSubscription}/>
			{(popUp) && <PopUpContainer subscription={subscription} managerSetter={setPopUp}/>}
		</SubscriptionContextProvider>
	</div>;
}

export default Subscriptions;