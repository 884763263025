import mockBalance from "../mock/mock-balance";
import TransferTool from "../components/transfer/TransferTool";

const Transfer = () => {
	return <div>
		<div className="flex">
			<TransferTool fromAccount={mockBalance.trustAccount} toAccount={mockBalance.kiwiAccount}/>
		</div>
	</div>
}

export default Transfer;