import {createContext, useContext, useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import transactionFilterBuilder from "../util/transactionFilter";
import {useTransactionFilter} from "./TransactionFilterContext";

const TransactionContext = createContext({});

const TransactionContextProvider = ({children, inmate}) => {

	const { filters } = useTransactionFilter();

	const initialLoad = useRef(true);
	const transactions = useRef([]);
	const nextToken = useRef(undefined);
	const [sanitizedTransactions, setSanitizedTransactions] = useState([...transactions.current]);

	const filterTransactions = transactionFilterBuilder(false, true, true);

	const extractToken = ({nextPage}) => {
		return (nextPage) ? nextPage[0].nextToken : undefined;
	}

	const siphonTransactions = () => {
		const _transactions = filterTransactions(transactions.current, filters);
		setSanitizedTransactions([..._transactions]);
	}

	const setRefs = (response) => {
		transactions.current = [...transactions.current, ...response.items.filter(transaction => transaction.status === "APPROVED")];
		nextToken.current = extractToken(response);
	}

	const initializeHistory = async () => {

		if(!initialLoad.current && !nextToken.current)
			return { transactions: transactions.current, nextToken: nextToken.current };

		while(transactions.current.length < 10){

			const response = await inmate.api.inmate.allTransactions({
				id: inmate.id,
				nextPage: nextToken.current
			});

			setRefs(response);

			if(!nextToken.current) break;

		}

		siphonTransactions();

		initialLoad.current = false;

		return { transactions: transactions.current, nextToken: nextToken.current };

	}

	const retrieveMoreTransactions = async () => {

		do {

			if(!nextToken.current) break;

			const response = await inmate.api.inmate.allTransactions({
				id: inmate.id,
				nextPage: nextToken.current
			});

			setRefs(response);

			siphonTransactions();
			
		} while(transactions.current.length < 10 && sanitizedTransactions.length < 5 && nextToken.current);

		return { transactions: transactions.current, nextToken: nextToken.current };
		
	}

	const {
		status: fetchStatus,
		refetch: fetchMoreTransactions,
		isRefetchError: errorOnRetrieval,
		isRefetching: currentlyFetchingTransactions,
	} = useQuery('FetchMoreTransactions', retrieveMoreTransactions, { enabled: false });
	const { data, status: initialLoadStatus } = useQuery('InitializeHistory', initializeHistory);

	useEffect(() => {
		siphonTransactions();
	}, [filters]);

	useEffect(() => {
		if(sanitizedTransactions.length < 6) retrieveMoreTransactions().then(() => {
		});
	}, [sanitizedTransactions])

	return <TransactionContext.Provider value={{
		data,
		fetchStatus,
		transactions,
		errorOnRetrieval,
		initialLoadStatus,
		siphonTransactions,
		fetchMoreTransactions,
		sanitizedTransactions,
		currentlyFetchingTransactions,
	}}>
		{children}
	</TransactionContext.Provider>
}

const useTransactionContext = () => {
	const context = useContext(TransactionContext);
	return context;
}

export { TransactionContextProvider, useTransactionContext };