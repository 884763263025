import filterMenuIcons from "../../../assets/filter-menu-icons";

const FilterHeader = ({handler}) => {

	return <div className="flex text-[3.78vh]">
		<div className="w-full font-dm-sans-bold">Filters</div>
		<div className="w-full">
			<div className="float-right py-[0.3vh]">
				<filterMenuIcons.close fill="#101010" onClick={handler}/>
			</div>
		</div>
	</div>
}

export default FilterHeader;