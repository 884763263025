const statuses = {
	"Done": false,
	"Pending": false,
	"Declined": false
}

const filterItems = {
	"Chat": false,
	"VideoChat": false,
	"Music": false,
	"Movies and TV Shows": false,
	"eBooks": false,
	"Podcasts": false
}

const transactionType = {
	"Purchase": false,
	"Subscription": false,
	"Transfer": false,
}

const transactionFiltersTemplate = {
	filterItems,
	statuses,
	transactionType,
}

export default transactionFiltersTemplate;