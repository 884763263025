import {createContext, useContext, useRef} from "react";
import {useQuery} from "react-query";

const PaymentHistoryContext = createContext({});

const PaymentHistoryContextProvider = ({children, inmate, subscription}) => {

	const initialLoad = useRef(true);
	const paymentHistory = useRef([]);
	const nextToken = useRef(undefined);

	const subscriptionTransactions = async () => {
		return inmate.api.inmate.subscriptionTransactions({
			id: subscription.kiwiSubscriptionId,
			nextPage: nextToken.current,
		});
	}

	const extractToken = ({nextPage}) => {
		return (nextPage) ? nextPage[0].nextToken : undefined;
	}

	const setRefs = (response) => {
		paymentHistory.current = [
			...paymentHistory.current,
			...response.items.filter(transaction => transaction.status === "APPROVED")
		];
		nextToken.current = extractToken(response);
	}

	const fetchSubscriptionTransactions = async () => {

		if(!initialLoad.current && !nextToken.current)
			return { paymentHistory: paymentHistory.current, nextToken: nextToken.current };

		const response = await subscriptionTransactions();

		setRefs(response);

		initialLoad.current = false;

	}

	const {
		data,
		status: fetchStatus,
		isRefetchError: errorOnRetrieval,
		refetch: fetchMoreSubscriptionTransactions,
		isRefetching: currentlyFetchingSubscriptionTransactions,
	} = useQuery('FetchSubscriptionTransactions', fetchSubscriptionTransactions)

	return <PaymentHistoryContext.Provider value={{
		data,
		nextToken,
		fetchStatus,
		subscription,
		paymentHistory,
		errorOnRetrieval,
		fetchMoreSubscriptionTransactions,
		currentlyFetchingSubscriptionTransactions,
	}}>
		{children}
	</PaymentHistoryContext.Provider>

}

const usePaymentHistoryContext = () => {
	const context = useContext(PaymentHistoryContext);
	return context;
}

export { PaymentHistoryContextProvider, usePaymentHistoryContext };