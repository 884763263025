import historyIcons from "../../../assets/history-icons";
import {useEffect, useState} from "react";

const Title = ({name, description, transactionType, clicked}) => {

	const [width, setWidth] = useState(' w-[40%]');

	const transition = " transition-width transition-slowest ease duration-[2000ms]";

	useEffect(() => {
		setWidth((clicked) ? ' w-full' : ' w-[40%]');
	}, [clicked])

	return <div className={"text-[3.02vh] " + transition + width}>
		<div className="flex relative">
			<div className="flex justify-center items-center mr-[1.52vh]">
				{
					(transactionType === "TRANSFER") ?
						<historyIcons.EXCHANGE bgFill="#101010" arrowFill="white"/> :
						<historyIcons.PURCHASE bgFill="#101010" arrowFill="white"/>
				}
			</div>
			<div className={"w-[90%] truncate" + transition}>
				<div className={"font-dm-sans truncate" + transition}>{
					name
				}</div>
				<div className={"text-[2.265vh] font-dm-sans text-[#9191A1] truncate" + transition}>{
					description
				}</div>
			</div>
		</div>
	</div>;
}

export default Title;