import Loading from "../shared/Loading";
import historyIcons from "../../assets/history-icons";
import TransactionContainer from "./TransactionContainer";
import {useTransactionContext} from "../../context/TransactionContext";

const TransactionHeader = ({handler, isLoading}) => {
	return <div className="py-[2.3vh] flex">
		<div className="w-full">
			<div className="font-dm-sans text-[3.25vh]">Transaction history</div>
		</div>
		<div className="relative w-full">
			<div className="relative text-[2.7vh] font-dm-sans top-[2vh]">
				<div
					className="flex float-right w-auto"
					onClick={handler}
				>
					<div className="relative mr-[1.5vh]">
						<historyIcons.filter fill="#101010"/>
					</div>
					<div className="relative">Filters</div>
				</div>
			</div>
		</div>
	</div>
}

const TransactionTitleHeaders = () => {
	return <div className="flex text-[2.05vh] text-[#9191A1] mt-[3vh] mb-[0.5vh] relative">
		<div>Transaction</div>
		<div className="absolute right-[8.3%]">
			Amount
		</div>
		<div className="absolute left-[50%]">
			Date
		</div>
	</div>
}

const Transactions = ({menuOpenSetter}) => {

	const { initialLoadStatus } = useTransactionContext();
	const handler = () => menuOpenSetter(true);

	return <div className="relative w-[92.5%] mx-auto h-screen animate-component-slide-up">
		<TransactionHeader handler={handler}/>
		<div className="bg-white w-full h-[66%] rounded-[4.3vh] pl-[3.4%] pr-[1.7%] pt-[3.2vh]">
			<div className="h-[83%]">
				<TransactionTitleHeaders />
				{
					(initialLoadStatus === "loading") ?
						<Loading message="Retrieving your transactions..." /> :
						<TransactionContainer />
				}
			</div>
		</div>
	</div>
}

export default Transactions;