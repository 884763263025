import {useTransactionFilter} from "../../../context/TransactionFilterContext";

const FilterApplier = ({typeSelections, setTypeSelections, itemSelections, setItemSelections, handleClick}) => {

	const { filters, setFilters } = useTransactionFilter();

	const resetFilters = () => {
		const resetTypes = { ...typeSelections };
		const resetItems = { ...itemSelections };
		Object.keys(resetTypes).forEach(key => {
			resetTypes[key] = false;
		});
		Object.keys(resetItems).forEach(key => {
			resetItems[key] = false;
		});
		setTypeSelections(resetTypes);
		setItemSelections(resetItems);
	}

	const applyFilters = () => {
		const newFilters = { ...filters };
		Object.assign(newFilters, {transactionType: typeSelections, filterItems: itemSelections});
		setFilters(newFilters);
		handleClick();
	}

	return <div className="flex float-right py-[3.7vh] text-[3.02vh] font-dm-sans-medium">
		<div className="px-[7.5vh] py-[2.23vh] border border-[#EBEBEF] rounded-[1.53vh] mr-[3vh]" onClick={resetFilters}>
			<div>Reset</div>
		</div>
		<div className="px-[3.75vh] py-[2.23vh] rounded-[1.53vh] bg-[#101010]" onClick={applyFilters}>
			<div className="text-white">Apply Filters</div>
		</div>
	</div>
}

export default FilterApplier;