const AccountInfo = () => {

	const TrustAccount = {
		name: "Trust Account",
		description: "When an inmate is admitted into custody, " +
			"the money collected at the time of intake is deposited " +
			"into the inmate’s individual trust account. Funds are " +
			"automatically used to pay for the intake processing fees, " +
			"outstanding debt from previous accounts, and daily subsistence " +
			"fees. Friends and family may also make deposits into trust accounts.",
		disclaimer: "The displayed amount may not be representative of the actual " +
			"trust account balance.",
		source: "https://www.miamidade.gov/global/service.page?Mduid_service=ser1513021061627310#:~:text=When%20an%20inmate%20is%20admitted,accounts%2C%20and%20daily%20subsistence%20fees."
	}

	const KiwiAccount = {
		name: "Kiwi Account",
		description: "When registering with KiwiChat all inmates are provided " +
			"a Kiwi Account where their KiwiCoins are stored in. KiwiCoins may " +
			"only be used on items related or affiliated with KiwiChat.  Deposits " +
			"to a Kiwi Account can be done through transfers from an inmate trust " +
			"account, or through friends and family performing a transfer."
	}

	return { TrustAccount, KiwiAccount };

}

export default AccountInfo;