import React from 'react';

const KiwiLogo = () => {
	return <svg
		width="16" height="16"
		viewBox="0 0 16 16"
		fill="none" xmlns="http://www.w3.org/2000/svg"
		className="relative w-[1em] h-[1em] top-[0.2em]"
	>
		<path d="M13.6612 2.34812C12.153 0.840956 10.1202 0 8 0H0V7.99454C0 10.1133 0.852459
		12.1447 2.34973 13.641C3.84699 15.1481 5.87978 15.9891 8 16H16V7.99454C16 5.87577
		15.1585 3.84437 13.6612 2.34812ZM6.44809 11.7406C5.95628 11.5331 5.5082 11.2382
		5.13661 10.8669C4.76503 10.4956 4.45902 10.0478 4.2623 9.55631C4.05465 9.06485 3.95628
		8.54061 3.95628 8.00546C3.94536 5.76655 5.75956 3.92082 8 3.92082C8.53552 3.92082
		9.06011 4.03003 9.55191 4.22662C10.0437 4.43413 10.4918 4.72901 10.8634 5.10034C11.235
		5.47167 11.541 5.91945 11.7377 6.41092C11.9454 6.90239 12.0437 7.42662 12.0437
		7.96177V11.9918V12.0137V12.0464H8C7.46448 12.0464 6.93989 11.9372 6.44809 11.7406Z" fill="#038768"/>
	</svg>;
}

const WalletIcon = () => {
	return <svg
		width="24" height="24"
		viewBox="0 0 24 24" fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="relative w-[1.5em] h-[1.5em]"
	>
		<path d="M17.5 8V6C17.5 4.89543 16.6046 4 15.5 4H5C3.89543 4 3 4.89543 3 6V8V18.5C3
		19.6046 3.89543 20.5 5 20.5H18C19.1046 20.5 20 19.6046 20 18.5V17" strokeWidth="2"/>
		<rect x="16" y="12" width="6" height="4" rx="1" strokeWidth="2"/>
		<path d="M20 11V9C20 7.89543 19.1046 7 18 7H3" strokeWidth="2"/>
	</svg>
}

const TransactionIcon = () => {
	return <svg
		width="24" height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		className="relative w-[1.5em] h-[1.5em]"
	>
		<path fillRule="evenodd" clipRule="evenodd" d="M14.4466 12.6054L14.9769 13.1358L18.7805
		 16.9393C19.0733 17.2322 19.0733 17.7071 18.7805 18L14.9769 21.8035L14.4466
		 22.3338L13.3859 21.2732L13.9163 20.7428L16.309 18.3501L6.50002 18.3501L5.75002
		 18.3501L5.75002 16.8501L6.50002 16.8501L16.57 16.8501L13.9163 14.1964L13.3859 13.6661L14.4466
		 12.6054ZM8.28194 11.4178L7.75161 10.8875L3.94808 7.08397C3.65519 6.79108 3.65519 6.3162
		 3.94808 6.02331L7.75161 2.21978L8.28194 1.68945L9.3426 2.75011L8.81227 3.28044L6.28895
		 5.80376L17 5.80376L17.75 5.80376L17.75 7.30376L17 7.30376L6.2892 7.30376L8.81227 9.82684L9.3426
		 10.3572L8.28194 11.4178Z"/>
	</svg>
}

const SubscriptionIcon = () => {
	return <svg
		width="24" height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		className="relative w-[1.5em] h-[1.5em]"
	>
		<path fillRule="evenodd" clipRule="evenodd" d="M6 21C3.79086 21 2 19.2091 2 17V15C2 13.8954
		2.89543 13 4 13H6.99998V4.99583C6.99998 3.89126 7.89541 3 8.99998 3H20C21.1045 3 22
		3.89543 22 5V17C22 19.2091 20.2091 21 18 21H6ZM8.18934 19.5H18C19.3807 19.5 20.5 18.3807
		20.5 17V5C20.5 4.72386 20.2761 4.5 20 4.5H8.99998C8.71976 4.5 8.49998 4.72376 8.49998 4.99583L8.5
		18C8.5 18.5858 8.37132 19.0858 8.18934 19.5ZM5.14294 19.3492C5.5327 19.403 5.95664 19.3843 6.30093
		19.1792C6.70543 18.9383 6.99998 18.4401 6.99998 17.5V14.5H4C3.72386 14.5 3.5 14.7239 3.5 15V17C3.5
		18.0796 4.18436 18.9994 5.14294 19.3492ZM18.5 8.75H10.5C10.0858 8.75 9.75 9.08579 9.75 9.5C9.75
		9.91421 10.0858 10.25 10.5 10.25H18.5C18.9142 10.25 19.25 9.91421 19.25 9.5C19.25 9.08579 18.9142
		8.75 18.5 8.75ZM18.5 12.75H10.5C10.0858 12.75 9.75 13.0858 9.75 13.5C9.75 13.9142 10.0858 14.25
		10.5 14.25H18.5C18.9142 14.25 19.25 13.9142 19.25 13.5C19.25 13.0858 18.9142 12.75 18.5 12.75Z"/>
	</svg>
}

const navIcons = [
	{
		key: 1,
		icon: <KiwiLogo/>,
		name: 'Wallet'
	},
	{
		key: 2,
		icon: <WalletIcon/>,
		name: 'Transfers',
 	},
	{
		key: 3,
		icon: <TransactionIcon/>,
		name: 'Transaction History'
	},
	{
		key: 4,
		icon: <SubscriptionIcon/>,
		name: 'Subscriptions'
	},
]

export default navIcons;