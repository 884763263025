import dateFormatters, {historyDateFormatter} from "../../../util/dateFormatters";
import {usePaymentHistoryContext} from "../../../context/PaymentHistoryContext";
import amountFormatter from "../../../util/amountFormatter";

const SubscriptionHeader = () => {

	const { subscription } = usePaymentHistoryContext();
	const { active, purchasedOn, product: { kiwiPrice, period }, charge: { TTL, isCancelled } } = subscription;

	return <div className="flex text-[2.643vh] font-dm-sans">
		<div>
			<div className={((active) ? "text-[#03A882]" : "text-[#9191A1]") + " mb-[0.67vh]"}>
				<div>{(active) ? "Active" : "In-Active"}</div>
			</div>
			<div className="border-[#E6E6E7] border-r">
				<div className="mr-[3.8vh]">{`${amountFormatter(kiwiPrice)} / ${period.toLowerCase()}`}</div>
			</div>
		</div>
		<div>
			<div className="ml-[3.05vh] text-[#9191A1] mb-[0.67vh]">Start Date</div>
			<div className="border-[#E6E6E7] border-r">
				<div className="ml-[3.05vh] mr-[3.4vh]">{dateFormatters.historyDateFormatter(new Date(purchasedOn).getTime())}</div>
			</div>
		</div>
		<div>
			<div className="ml-[3.05vh] text-[#9191A1] mb-[0.67vh]">Next Payment</div>
			<div>
				<div className="ml-[3.05vh] mr-[3.4vh]">{
					historyDateFormatter((TTL && active && !isCancelled) ? new Date(TTL * 1000) : undefined)
				}</div>
			</div>
		</div>
	</div>
}

export default SubscriptionHeader;